<template>
    <main class="main">
        <div class="container-fluid px-0" data-layout="container">

            <aside-main/>

            <header-main/>

            <div class="content">

                <div class="card-header mb-5 d-flex flex-between-center">
                    <div class="d-flex align-items-center">
                        <h2 class="text-bold text-1100">
                            Провайдеры
                            <span class="fw-normal text-700 ms-2">({{items.length}})</span>
                        </h2>
                    </div>

                    <div class="d-flex">
                        <button
                            @click="$modal.show('create-item')"
                            class="btn btn-primary me-1 mb-1 float_right"
                            type="button">
                            Создать
                        </button>
                    </div>
                </div>

                <div class="row align-items-center justify-content-between g-3 mb-3">
                    <div class="col-sm-6 col-md-6">
                        <div class="form-icon-container">
                            <input
                                @input="getItems"
                                v-model="search.name"
                                type="text" 
                                placeholder="Название" 
                                class="form-control form-icon-input">
                            <span class="fa-regular fa-magnifying-glass text-500 fs--1 form-icon"></span>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-6">
                        <div class="form-icon-container">
                            <vue-select
                                @input="getItems"
                                v-model="search.status"
                                :options="utils.statuses" 
                                placeholder="Статус"
                                label="name">
                                <template v-slot:no-options="{ search, searching }">
                                  <template v-if="searching">
                                     Ничего не найдено по запросу <em>{{ search }}</em>.
                                  </template>
                                </template>
                            </vue-select>
                        </div>
                    </div>
                </div>


                <div class="card-body p-0 spinner-center" v-if="preloader">
                    <div class="p-4 code-to-copy">
                        <div class="spinner-border" role="status"></div>
                    </div>
                </div>


                <div class="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-white border-y border-300 mt-2 position-relative top-1" v-show="!preloader">
                    <div class="table-responsive scrollbar ms-n1 ps-1">
                    
                        <div class="table table-responsive-small">
                            <datatable
                                :columns="columns" 
                                :data="items"
                                :per-page="pager.per_page">
                                <template scope="{ row, columns }">
                                    <tr>
                                        <td>{{ row.id }}</td>
                                        <td>{{ row.name }}</td>
                                        <td>{{ row.count_games }}</td>
                                        <td>{{ utils.statuses.find(el => el.key == row.status).name }}</td>
                                        <td>
                                            <button
                                                @click="changeStatus(row.id)"
                                                :content="row.status == 1 ? 'Отключить' : 'Включить'" 
                                                v-tippy="{arrow : true, arrowType: 'round'}"
                                                :class="{'disabled': status_item_preloader}"
                                                class="btn btn-soft-secondary btn-sm me-1 mb-1">
                                                <span class="spinner-border spinner-border-sm spinner-border-custom-small" role="status" v-if="status_item_preloader && status_item_preloader == row.id"></span>
                                                <i
                                                    v-if="!status_item_preloader || status_item_preloader != row.id"
                                                    :class="{'fa-circle-pause': row.status == 1, 'fa-play': row.status == 0}"
                                                    class="fa-regular fa-circle-pause"></i>
                                            </button>
                                            <button
                                                @click="$modal.show('update-item'), data.update = row"
                                                content="Редактировать" 
                                                v-tippy="{arrow : true, arrowType: 'round'}"
                                                class="btn btn-soft-primary btn-sm me-1 mb-1">
                                                <i class="fa-regular fa-pen-to-square"></i>
                                            </button>
                                            <button
                                                v-if="1==2"
                                                @click="deleteItem(row.id)"
                                                content="Удалить" 
                                                v-tippy="{arrow : true, arrowType: 'round'}"
                                                class="btn btn-soft-danger btn-sm me-1 mb-1">
                                                <i class="fa-regular fa-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </template>
                            </datatable>
                        </div>

                        <div class="col-auto d-flex">
                            <ul class="mb-3 pagination">
                                <datatable-pager v-model="pager.page" type="abbreviated"></datatable-pager>
                            </ul>
                        </div>

                    </div>
                </div>


                <!-- Modals -->

                <modal 
                    name="create-item"
                    height="auto"
                    :shiftY="0.3"
                    :adaptive="true">
                    <form class="row g-3" v-on:submit.prevent="createItem">
                        <div class="col-sm-12 col-md-12">
                            <div class="form-floating">
                                <input
                                    v-model="data.create.name"
                                    @input="errors.create.name = null"
                                    :class="{'is-invalid': errors.create.name}"
                                    placeholder="Название"
                                    class="form-control" 
                                    type="text"/>
                                <label>Название</label>
                                <div class="invalid-feedback">{{errors.create.name}}</div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-floating">
                                <input
                                    v-model="data.create.slug"
                                    @input="errors.create.slug = null"
                                    :class="{'is-invalid': errors.create.slug}"
                                    placeholder="Slug"
                                    class="form-control" 
                                    type="text"/>
                                <label>Slug</label>
                                <div class="invalid-feedback">{{errors.create.slug}}</div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-12">
                            <div class="form-floating">
                                <input
                                    v-model="data.create.merchants.white"
                                    @input="errors.create.merchants.white = null"
                                    :class="{'is-invalid': errors.create.merchants.white}"
                                    placeholder="Мерчанты (White List)"
                                    class="form-control" 
                                    type="text"/>
                                <label>Мерчанты (White List)</label>
                                <div class="invalid-feedback">{{errors.create.merchants.white}}</div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-floating">
                                <input
                                    v-model="data.create.merchants.black"
                                    @input="errors.create.merchants.black = null"
                                    :class="{'is-invalid': errors.create.merchants.black}"
                                    placeholder="Мерчанты (Black List)"
                                    class="form-control" 
                                    type="text"/>
                                <label>Мерчанты (Black List)</label>
                                <div class="invalid-feedback">{{errors.create.merchants.black}}</div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-12">
                            <div class="form-floating">
                                <input
                                    v-model="data.create.countries.white"
                                    @input="errors.create.countries.white = null"
                                    :class="{'is-invalid': errors.create.countries.white}"
                                    placeholder="Страны (White List)"
                                    class="form-control" 
                                    type="text"/>
                                <label>Страны (White List)</label>
                                <div class="invalid-feedback">{{errors.create.countries.white}}</div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-floating">
                                <input
                                    v-model="data.create.countries.black"
                                    @input="errors.create.countries.black = null"
                                    :class="{'is-invalid': errors.create.countries.black}"
                                    placeholder="Страны (Black List)"
                                    class="form-control" 
                                    type="text"/>
                                <label>Страны (Black List)</label>
                                <div class="invalid-feedback">{{errors.create.countries.black}}</div>
                            </div>
                        </div>

                        <div class="col-sm-3 col-md-3">
                            <button class="btn btn-primary px-5 w-100 text-nowrap" type="submit" :class="{'disabled': btn_preloader}">
                                <span class="spinner-border spinner-border-sm spinner-border-custom" role="status" v-if="btn_preloader"></span>
                                <span>Создать</span>
                            </button>
                        </div>
                    </form>
                </modal>

                <modal 
                    name="update-item"
                    height="auto"
                    :shiftY="0.3"
                    :adaptive="true">
                    <form class="row g-3" v-on:submit.prevent="updateItem">
                        <div class="col-sm-12 col-md-12">
                            <div class="form-floating">
                                <input
                                    v-model="data.update.name"
                                    @input="errors.update.name = null"
                                    :class="{'is-invalid': errors.update.name}"
                                    placeholder="Название"
                                    class="form-control" 
                                    type="text"/>
                                <label>Название</label>
                                <div class="invalid-feedback">{{errors.update.name}}</div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-floating">
                                <input
                                    v-model="data.update.slug"
                                    @input="errors.update.slug = null"
                                    :class="{'is-invalid': errors.update.slug}"
                                    disabled
                                    placeholder="Slug"
                                    class="form-control" 
                                    type="text"/>
                                <label>Slug</label>
                                <div class="invalid-feedback">{{errors.update.slug}}</div>
                            </div>
                        </div>

                        <div class="col-sm-6 col-md-6">
                            <div class="form-floating v-select-medium" :class="{'v-select-error': errors.update.merchants.white}">
                                <vue-select
                                    multiple
                                    @input="errors.update.merchants.white = null"
                                    v-model="data.update.merchants.white"
                                    :options="utils.merchants" 
                                    placeholder="Мерчанты (White List)"
                                    label="name">
                                    <template v-slot:no-options="{ search, searching }">
                                      <template v-if="searching">
                                         Ничего не найдено по запросу <em>{{ search }}</em>.
                                      </template>
                                    </template>
                                </vue-select>
                                <label>Мерчанты (White List)</label>
                                <div class="invalid-feedback">{{ errors.update.merchants.white }}</div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6">
                            <div class="form-floating v-select-medium" :class="{'v-select-error': errors.update.merchants.black}">
                                <vue-select
                                    multiple
                                    @input="errors.update.merchants.black = null"
                                    v-model="data.update.merchants.black"
                                    :options="utils.merchants" 
                                    placeholder="Мерчанты (Black List)"
                                    label="name">
                                    <template v-slot:no-options="{ search, searching }">
                                      <template v-if="searching">
                                         Ничего не найдено по запросу <em>{{ search }}</em>.
                                      </template>
                                    </template>
                                </vue-select>
                                <label>Мерчанты (Black List)</label>
                                <div class="invalid-feedback">{{ errors.update.merchants.black }}</div>
                            </div>
                        </div>

                        <div class="col-sm-6 col-md-6">
                            <div class="form-floating v-select-medium" :class="{'v-select-error': errors.update.countries.white}">
                                <vue-select
                                    multiple
                                    @input="errors.update.countries.white = null"
                                    v-model="data.update.countries.white"
                                    :options="utils.countries" 
                                    placeholder="Страны (White List)"
                                    label="name">
                                    <template v-slot:no-options="{ search, searching }">
                                      <template v-if="searching">
                                         Ничего не найдено по запросу <em>{{ search }}</em>.
                                      </template>
                                    </template>
                                </vue-select>
                                <label>Страны (White List)</label>
                                <div class="invalid-feedback">{{ errors.update.countries.white }}</div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6">
                            <div class="form-floating v-select-medium" :class="{'v-select-error': errors.update.countries.black}">
                                <vue-select
                                    multiple
                                    @input="errors.update.countries.black = null"
                                    v-model="data.update.countries.black"
                                    :options="utils.countries" 
                                    placeholder="Страны (Black List)"
                                    label="name">
                                    <template v-slot:no-options="{ search, searching }">
                                      <template v-if="searching">
                                         Ничего не найдено по запросу <em>{{ search }}</em>.
                                      </template>
                                    </template>
                                </vue-select>
                                <label>Страны (Black List)</label>
                                <div class="invalid-feedback">{{ errors.update.countries.black }}</div>
                            </div>
                        </div>

                        <div class="col-sm-3 col-md-3">
                            <button class="btn btn-primary px-5 w-100 text-nowrap" type="submit" :class="{'disabled': btn_preloader}">
                                <span class="spinner-border spinner-border-sm spinner-border-custom" role="status" v-if="btn_preloader"></span>
                                <span>Сохранить</span>
                            </button>
                        </div>
                    </form>
                </modal>
                
                <footer-main/>

            </div>

        </div>
    </main>
</template>

<script>
export default {
    props: ['status'],
    data () {
        return {
            preloader: true,
            btn_preloader: false,
            status_item_preloader: false,
            pager: {
                per_page: 25,
                page: 0
            },
            items: [],
            search: {
                name: null,
                status: null
            },
            columns: [
                {label: 'ID'},
                {label: 'Название'},
                {label: 'Кол. игр'},
                {label: 'Статус'},
                {label: ''}
            ],
            data: {
                create: {
                    name: null,
                    merchants: {
                        white: [],
                        black: []
                    },
                    countries: {
                        white: [],
                        black: []
                    }
                },
                update: {
                    id: null,
                    name: null,
                    merchants: {
                        white: [],
                        black: []
                    },
                    countries: {
                        white: [],
                        black: []
                    }
                }
            },
            errors: {
                create: {
                    name: false,
                    merchants: {
                        white: false,
                        black: false
                    },
                    countries: {
                        white: false,
                        black: false
                    }
                },
                update: {
                    id: false,
                    name: false,
                    merchants: {
                        white: false,
                        black: false
                    },
                    countries: {
                        white: false,
                        black: false
                    }
                }
            },
            utils: {
                merchants: [],
                countries: [],
                statuses: [
                    {
                        name: 'Включен',
                        key: 1
                    },
                    {
                        name: 'Отключен',
                        key: 0
                    }
                ]
            }
        }
    },
    methods: {
        getItems () {
            this.preloader = true
            this.$axios.post('/games/providers/items', JSON.stringify({
                search: {
                    name: this.search.name,
                    status: this.search.status != null ? this.search.status.key : null
                }
            }))
            .then((response) => {
                this.preloader = false
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        this.items = response.data

                        for (let item of this.items) {
                            var merchants = item.merchants
                            var countries = item.countries

                            let item_index = this.items.findIndex(row => row.id == item.id)

                            this.items[item_index].merchants = {
                                white: [],
                                black: []
                            }
                            this.items[item_index].countries = {
                                white: [],
                                black: []
                            }

                            for (let item_ of merchants.white) {
                                this.items[item_index].merchants.white.push(this.utils.merchants.find(row => row.id == item_))
                            }
                            for (let item_ of merchants.black) {
                                this.items[item_index].merchants.black.push(this.utils.merchants.find(row => row.id == item_))
                            }
                            for (let item_ of countries.white) {
                                this.items[item_index].countries.white.push(this.utils.countries.find(row => row.key == item_))
                            }
                            for (let item_ of countries.black) {
                                this.items[item_index].countries.black.push(this.utils.countries.find(row => row.key == item_))
                            }
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                this.preloader = false
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })
        },
        createItem () {
            if(!this.data.create.name) {
                this.errors.create.name = 'Это поле обязательно'
                return
            }
            if(!this.data.create.slug) {
                this.errors.create.slug = 'Это поле обязательно'
                return
            }

            var merchants = {
                white: [],
                black: []
            }
            var countries = {
                white: [],
                black: []
            }

            for (let item of this.data.create.merchants.white) {
                merchants.white.push(item.id)
            }
            for (let item of this.data.create.merchants.black) {
                merchants.black.push(item.id)
            }

            for (let item of this.data.create.countries.white) {
                countries.white.push(item.key)
            }
            for (let item of this.data.create.countries.black) {
                countries.black.push(item.key)
            }
            
            this.btn_preloader = true
            this.$axios.post('/games/providers/create', JSON.stringify({
                name: this.data.create.name,
                slug: this.data.create.slug,
                merchants: merchants,
                countries: countries
            }))
            .then((response) => {
                this.btn_preloader = false
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        this.data.create.name = null
                        this.data.create.slug = null
                        this.getItems()
                        this.$modal.hide('create-item')
                        this.$toast.success(response.message)

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                this.btn_preloader = false
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors.create[response.field] = response.message
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })
        },
        updateItem () {
            if(!this.data.update.name) {
                this.errors.update.name = 'Это поле обязательно'
                return
            }
            if(!this.data.update.slug) {
                this.errors.update.slug = 'Это поле обязательно'
                return
            }

            var merchants = {
                white: [],
                black: []
            }
            var countries = {
                white: [],
                black: []
            }

            for (let item of this.data.update.merchants.white) {
                merchants.white.push(item.id)
            }
            for (let item of this.data.update.merchants.black) {
                merchants.black.push(item.id)
            }

            for (let item of this.data.update.countries.white) {
                countries.white.push(item.key)
            }
            for (let item of this.data.update.countries.black) {
                countries.black.push(item.key)
            }
            
            this.btn_preloader = true
            this.$axios.post('/games/providers/update', JSON.stringify({
                id: this.data.update.id,
                name: this.data.update.name,
                merchants: merchants,
                countries: countries
            }))
            .then((response) => {
                this.btn_preloader = false
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        this.getItems()
                        this.$modal.hide('update-item')
                        this.$toast.success(response.message)

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                this.btn_preloader = false
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors.update[response.field] = response.message
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })
        },
        changeStatus (id) {
            this.status_item_preloader = id
            this.$axios.post(`/games/providers/changestatus/${id}`)
            .then((response) => {
                this.status_item_preloader = false
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        let item_index = this.items.findIndex(row => row.id == id)
                        this.items[item_index].status = this.items[item_index].status == 1 ? 0 : 1

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        deleteItem (id) {
            this.$confirm('Вы уверены что хотите удалить провайдер?').then(() => {
                this.$axios.post('/games/providers/delete', JSON.stringify({
                    id: id
                }))
                .then((response) => {
                    response = response.data
                    switch (response.status) {
                        case "error":
                            this.$toast.error(response.message)
                            break
                        case "success":
                            this.getItems()
                            this.$toast.success(response.message)

                            this.setUser(response.user)
                            break
                        default:
                            this.$toast.error('Unknown error')
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if('status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            })
        },
        async getMerchants () {
            await this.$axios.post('/merchants/items')
            .then((response) => {
                this.preloader = false
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        for (let item of response.data) {
                            this.utils.merchants.push({
                                name: item.name,
                                id: item.id
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        async getCountries () {
            await this.$axios.post('/utils/countries/items')
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':

                        for(let item in response.data) {
                            this.utils.countries.push({
                                name: response.data[item],
                                key: item
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)
                
                if(response && 'status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        }
    },
    async beforeMount () {
        await this.getMerchants()
        await this.getCountries()

        this.getItems()
    }
}
</script>
