<template>
    <main class="main">
        <div class="container-fluid px-0" data-layout="container">

            <aside-main/>

            <header-main/>

            <div class="content">
                <form class="mb-9" v-on:submit.prevent="updateItem">
                    <div class="row justify-content-between align-items-end g-3 mb-5">
                        <div class="col-12 col-sm-auto col-xl-8">
                            <h2>Карточка соревнования</h2>
                        </div>
                        <div class="col-12 col-sm-auto col-xl-4">
                            <div class="d-flex">
                                <button type="submit" class="btn btn-primary px-5 w-100 text-nowrap" :class="{'disabled': btn_preloader}">
                                    <span class="spinner-border spinner-border-sm spinner-border-custom" role="status" v-if="btn_preloader"></span>
                                    <span>Сохранить</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="card-body p-0 spinner-center" v-if="preloader">
                        <div class="p-4 code-to-copy">
                            <div class="spinner-border" role="status"></div>
                        </div>
                    </div>

                    <div class="row g-5" v-if="!preloader">
                        <h4 class="fs-1">Основная информация</h4>

                        <div class="row g-3">
                            <div class="col-sm-6 col-md-12">
                                <div class="form-floating">
                                    <input
                                        v-model="data.name"
                                        @input="errors.name = null"
                                        :class="{'is-invalid': errors.name}"
                                        placeholder="Название"
                                        class="form-control" 
                                        type="text"/>
                                    <label>Название</label>
                                    <div class="invalid-feedback">{{errors.name}}</div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-md-6">
                                <div class="form-floating">
                                    <date-range-picker
                                        :class="{'is-invalid': errors.date}"
                                        @update="errors.date = null"
                                        :locale-data="datepiker"
                                        :opens="pikerparams.opens"
                                        :always-show-calendars="pikerparams.showcalendars"
                                        :close-on-esc="pikerparams.closeonesc"
                                        :auto-apply="pikerparams.autoapply"
                                        :time-picker="pikerparams.timePicker"
                                        :time-picker24-hour="pikerparams.timePicker24Hour"
                                        v-model="data.date">
                                        <template #ranges="ranges">
                                          <div class="ranges">
                                            <ul>
                                              <li 
                                                v-for="(range, name, key) in pikerranges" 
                                                :key="name" 
                                                @click="ranges.clickRange(range, key)">
                                                    {{name}}
                                              </li>
                                            </ul>
                                          </div>
                                        </template>
                                    </date-range-picker>

                                    <label>Период проведения</label>
                                    <div class="invalid-feedback">{{errors.date}}</div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-md-6">
                                <div class="form-floating">
                                    <input
                                        v-model="data.min_bet"
                                        @input="errors.min_bet = null"
                                        :class="{'is-invalid': errors.min_bet}"
                                        placeholder="Мин. ставка"
                                        class="form-control" 
                                        type="text"/>
                                    <label>Мин. ставка</label>
                                    <div class="invalid-feedback">{{errors.min_bet}}</div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-md-6">
                                <div class="form-floating">
                                    <input
                                        v-model="data.exp_bet"
                                        @input="errors.exp_bet = null"
                                        :class="{'is-invalid': errors.exp_bet}"
                                        placeholder="EXP. ставка"
                                        class="form-control" 
                                        type="text"/>
                                    <label>EXP. ставка</label>
                                    <div class="invalid-feedback">{{errors.exp_bet}}</div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-md-6">
                                <div class="form-floating v-select-medium" :class="{'v-select-error': errors.merchant}">
                                    <vue-select
                                        @input="errors.merchant = null"
                                        v-model="data.merchant"
                                        :options="utils.merchants" 
                                        placeholder="Мерчант"
                                        label="name">
                                        <template v-slot:no-options="{ search, searching }">
                                          <template v-if="searching">
                                             Ничего не найдено по запросу <em>{{ search }}</em>.
                                          </template>
                                        </template>
                                    </vue-select>
                                    <label>Мерчант</label>
                                    <div class="invalid-feedback">{{errors.merchant}}</div>
                                </div>
                            </div>
                        </div>

                        <div class="row g-3">

                            <div class="card-header p-2 mt-3 d-flex flex-between-center" style="margin-bottom: -10px;">
                                <div class="d-flex align-items-center">
                                    <h4 class="fs-1">Призовой фонд <span class="text-700">{{totalPrize()}} {{data.merchant ? data.merchant.currency : null}}</span></h4>
                                </div>

                                <div class="d-flex">
                                    <a class="btn btn-primary mb-1 float_right" @click="addPrize">Новый приз</a>
                                </div>
                            </div>

                            <template v-for="(item,index) in data.prizes">
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating tournament-prize-raw">
                                        <div 
                                            content="Призовое место" 
                                            v-tippy="{arrow : true, arrowType: 'round'}"
                                            class="tournament-prize-number">
                                            <span class="vertical-center-item">{{index + 1}}</span>
                                        </div>
                                        <input
                                            v-model="data.prizes[index]"
                                            @input="errors.prizes[index] = null"
                                            :class="{'is-invalid': errors.prizes[index]}"
                                            placeholder="Сумма выигрыша"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Сумма выигрыша</label>

                                        <button 
                                            @click="deletePrize(index)"
                                            content="Удалить" 
                                            v-tippy="{arrow : true, arrowType: 'round'}"
                                            type="button" 
                                            class="btn btn-soft-danger tournament-button-right">
                                            <span class="vertical-center-item">
                                                <i class="fa-regular fa-trash"></i>
                                            </span>
                                        </button>
                                    </div>
                                    <div class="invalid-feedback">{{errors.prizes[index]}}</div>
                                </div>
                            </template>

                        </div>


                        <div class="row g-3">

                            <div class="card-header p-2 mt-3 d-flex flex-between-center" style="margin-bottom: -10px;">
                                <div class="d-flex align-items-center">
                                    <h4 class="fs-1">Пользователи</h4>
                                </div>
                            </div>

                            <div class="col-sm-6 col-md-12">
                                <div class="form-floating v-select-medium">
                                    <vue-select
                                        multiple
                                        :options="data.users" 
                                        label="query"
                                        placeholder="Участвующие"
                                        class="experience-users">
                                        <template v-slot:option="option">
                                            <span>{{ option.email }}:{{ option.username }}</span>

                                            <a 
                                                @click.stop="copyEmail(option.email)"
                                                content="Скопировать Email" 
                                                v-tippy="{arrow : true, arrowType: 'round'}"
                                                class="btn btn-sm btn-casino-primary ms-2" 
                                                style="position: absolute; right: 1rem; margin-top: -4px; pointer-events: auto; padding: 5px 10px 5px 10px;">
                                                <i class="fa-solid fa-copy"></i>
                                            </a>
                                        </template>
                                    </vue-select>
                                    <label>Участвующие</label>
                                </div>
                            </div>

                            <div class="col-sm-6 col-md-12" v-if="data.status == 1">
                                <div class="form-floating v-select-medium">
                                    <vue-select
                                        multiple
                                        :options="data.winners" 
                                        label="query"
                                        placeholder="Победители"
                                        class="experience-users">
                                        <template v-slot:option="option">
                                            <span>{{ option.email }}:{{ option.username }}</span>

                                            <a 
                                                @click.stop="copyEmail(option.email)"
                                                content="Скопировать Email" 
                                                v-tippy="{arrow : true, arrowType: 'round'}"
                                                class="btn btn-sm btn-casino-primary ms-2" 
                                                style="position: absolute; right: 1rem; margin-top: -4px; pointer-events: auto; padding: 5px 10px 5px 10px;">
                                                <i class="fa-solid fa-copy"></i>
                                            </a>
                                        </template>
                                    </vue-select>
                                    <label>Победители</label>
                                </div>
                            </div>

                        </div>
                    </div>
                </form>
                
                <footer-main/>

            </div>

        </div>
    </main>
</template>

<script>
export default {
    props: ['id'],
    data () {
        return {
            preloader: true,
            btn_preloader: false,
            data: {
                name: null,
                min_bet: null,
                exp_bet: null,
                merchant: null,
                total_amount: null,
                prizes: [],
                users: [],
                date: {
                  startDate: null,
                  endDate: null
                },
                status: 0
            },
            errors: {
                name: false,
                min_bet: false,
                exp_bet: false,
                merchant: false,
                total_amount: false,
                prizes: [],
                date: false,
                status: false
            },
            utils: {
                merchants: [],
            },
            datepiker: {
                direction: 'ltr',
                format: 'yyyy.mm.dd HH:MM',
                separator: ' - ',
                applyLabel: 'Применить',
                cancelLabel: 'Отмена',
                weekLabel: 'W',
                customRangeLabel: 'Настраиваемый диапазон',
                daysOfWeek: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
                monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                firstDay: 0
            },
            pikerparams: {
                showcalendars: true,
                closeonesc: true,
                autoapply: false,
                timePicker: true,
                timePicker24Hour: true,
                opens: 'left'
            },
            pikerranges: {
                'Один день': [this.$moment().toDate(), this.$moment().set({date: this.$moment().get('date') + 1}).toDate()],
                'Одна неделя': [this.$moment().toDate(), this.$moment().set({date: this.$moment().get('date') + 7}).toDate()],
                'Один месяц': [this.$moment().toDate(), this.$moment().set({date: this.$moment().get('date') + 30}).toDate()],
                'Два месяца': [this.$moment().toDate(), this.$moment().set({date: this.$moment().get('date') + 60}).toDate()],
                'Три месяца': [this.$moment().toDate(), this.$moment().set({date: this.$moment().get('date') + 90}).toDate()],
                'Пол года': [this.$moment().toDate(), this.$moment().set({date: this.$moment().get('date') + 180}).toDate()],
                'Один год': [this.$moment().toDate(), this.$moment().set({date: this.$moment().get('date') + 365}).toDate()]
            }
        }
    },
    methods: {
        getItem () {
            this.$axios.post(`/experience/item/${this.$props.id}`)
            .then((response) => {
                this.preloader = false
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        this.data = {
                            name: response.data.name,
                            merchant: this.utils.merchants.find(row => row.id == response.data.merchant_id),
                            min_bet: response.data.min_bet,
                            exp_bet: response.data.exp_bet,
                            prizes: response.data.prizes,
                            users: [],
                            winners: [],
                            date: {
                                startDate: this.$moment(response.data.start_at).format('Y-MM-DD HH:mm:ss'),
                                endDate: this.$moment(response.data.end_at).format('Y-MM-DD HH:mm:ss')
                            },
                            status: response.data.status
                        }

                        for(let prize of this.data.prizes) {
                            this.errors.prizes.push(false)
                        }

                        if(response.data.users) {
                            this.getUsers(response.data.users)
                        }
                        
                        if(response.data.winners) {
                            this.getWinners(response.data.winners)
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })     
        },
        updateItem () {
            if(!this.data.name) {
                this.errors.name = 'Это поле обязательно'
                return
            }
            if(!this.data.min_bet) {
                this.errors.min_bet = 'Это поле обязательно'
                return
            }
            if(!this.data.exp_bet) {
                this.errors.exp_bet = 'Это поле обязательно'
                return
            }
            if(!this.data.merchant) {
                this.errors.merchant = 'Это поле обязательно'
                return
            }
            if(!this.data.prizes || !this.data.prizes.length) {
                this.errors.prizes = 'Это поле обязательно'
                return
            }
            if(!this.data.date) {
                this.errors.date = 'Это поле обязательно'
                return
            }

            var prizes = []
            for (let prize of this.data.prizes) {
                prizes.push(parseFloat(prize))
            }
            
            this.btn_preloader = true
            this.$axios.post('/experience/update', JSON.stringify({
                id: this.$props.id,
                name: this.data.name,
                min_bet: this.data.min_bet,
                exp_bet: this.data.exp_bet,
                merchant: this.data.merchant.id,
                prizes: prizes,
                date: {
                    start: this.$moment(this.data.date.startDate).format('YYYY-MM-DD H:mm:ss'),
                    expire: this.$moment(this.data.date.endDate).format('YYYY-MM-DD H:mm:ss')
                }
            }))
            .then((response) => {
                this.btn_preloader = false
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        this.$toast.success(response.message)

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                this.btn_preloader = false
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errorByPatch(this.errors, response.field, response.message)
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })
        },
        async getMerchants () {
            await this.$axios.post('/merchants/items')
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        for (let item of response.data) {
                            this.utils.merchants.push({
                                name: item.name,
                                currency: item.currency,
                                id: item.id
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    } else {
                        this.$toast.success(response.message)
                    }
                }
            })     
        },
        getUsers (ids) {
             this.$axios.post('/users/search/ids', JSON.stringify({
                users: ids
             }))
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        for (let user of response.data) {
                            this.data.users.push({
                                id: user.id,
                                email: user.email,
                                username: user.username,
                                query: `${user.email}:${user.username}`
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })
        },
        getWinners (ids) {
             this.$axios.post('/users/search/ids', JSON.stringify({
                users: ids
             }))
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        for (let user of response.data) {
                            this.data.winners.push({
                                id: user.id,
                                email: user.email,
                                username: user.username,
                                query: `${user.email}:${user.username}`
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })
        },
        errorByPatch (layer, path, value) {
            path = path.split('.')

            path.forEach((value, index) => {
                if (!isNaN(value)) {
                    path[index] = parseInt(value)
                }
            })

            for (var i = 0; i < path.length; i++) {
                if (value != null && i + 1 === path.length) {
                    layer[path[i]] = value
                }
                layer = layer[path[i]]
            }
            return layer
        },
        totalPrize () {
            var num = 0

            for (let prize of this.data.prizes) {
                if(!isNaN(prize)) {
                   num += parseFloat(prize)
                }
            }

            return num
        },
        addPrize () {
            this.data.prizes.push(0)
            this.errors.prizes.push(false)
        },
        deletePrize (index) {
            if(this.data.prizes.length > 1) {
                this.data.prizes.splice(index, 1)
                this.errors.prizes.splice(index, 1)
            }
        },
        copyEmail (text) {
            navigator.clipboard.writeText(text)
            this.$toast.success('Скопировано')
        },
    },
    async beforeMount () {
        await this.getMerchants()

        this.getItem()
    }
}
</script>

<style lang="css" scoped>
    ::v-deep .vs__clear {
        display: none;
    }
    ::v-deep .experience-users .vs__dropdown-option {
        pointer-events: none;
    }
</style>
