<template>
    <main class="main">
        <div class="container-fluid px-0" data-layout="container">

            <aside-main/>

            <header-main/>

            <div class="content">

                <div class="card-body p-0 spinner-center" v-if="preloader">
                    <div class="p-4 code-to-copy">
                        <div class="spinner-border" role="status"></div>
                    </div>
                </div>

                <form class="mb-9" v-on:submit.prevent="updateItem">
                    <div class="row justify-content-between align-items-end g-3 mb-5">
                        <div class="col-12 col-sm-auto col-xl-8">
                            <h2>Редактировать Сообщение</h2>
                        </div>
                    </div>
                    <div class="row g-5" v-if="!preloader">
                        <div class="col-xl-12">
                            <div class="row g-3">

                                <div class="col-sm-6 col-md-12">
                                    <div class="form-check" style="float:right">
                                        <input 
                                            v-model="data.is_welcome"
                                            class="form-check-input" 
                                            id="is_welcome" 
                                            type="checkbox">
                                        <label class="form-check-label" for="is_welcome">Приветственное</label>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-12 mb-4">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.users}">
                                        <vue-select
                                            multiple
                                            @input="errors.users = null, selectUser()"
                                            @search="searchUsers"
                                            v-model="data.users"
                                            :options="utils.users" 
                                            placeholder="Пользователи"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                                <template v-if="searching && search.length >= 3">
                                                    Ничего не найдено по запросу <em>{{ search }}</em>.
                                                </template>
                                                <span v-else>Введите не менее 3 символов</span>
                                            </template>
                                        </vue-select>
                                        <label>Пользователи</label>
                                        <div class="invalid-feedback">{{errors.users}}</div>
                                    </div>
                                </div>

                                <template v-for="(item, key) in data.data">
                                    <h4 class="fs-1">{{ utils.languages.find(row => row.key == item.language).name }}</h4>

                                    <div class="col-sm-6 col-md-12">
                                        <div class="form-floating">
                                            <input
                                                v-model="data.data[key].title"
                                                @input="errors.data[key].title = null"
                                                :class="{'is-invalid': errors.data[key].title}"
                                                placeholder="Заголовок"
                                                class="form-control" 
                                                type="text"/>
                                            <label>Заголовок</label>
                                            <div class="invalid-feedback">{{errors.data[key].title}}</div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-6 col-md-12">
                                        <div class="form-floating">
                                            <textarea
                                                v-model="data.data[key].text"
                                                @input="errors.data[key].text = null"
                                                :class="{'is-invalid': errors.data[key].text}"
                                                placeholder="Текст"
                                                class="form-control"
                                                style="height:70px"/>
                                            </textarea>
                                            <label>Текст</label>
                                            <div class="invalid-feedback">{{errors.data[key].text}}</div>
                                        </div>
                                    </div>
                                </template>

                                
                                <div class="col-sm-3 col-md-3">
                                    <button class="btn btn-primary px-5 w-100 text-nowrap" type="submit" :class="{'disabled': btn_preloader}">
                                        <span class="spinner-border spinner-border-sm spinner-border-custom" role="status" v-if="btn_preloader"></span>
                                        <span>Сохранить</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                
                <footer-main/>

            </div>

        </div>
    </main>
</template>

<script>
export default {
    props: ['id'],
    data () {
        return {
            preloader: true,
            btn_preloader: false,
            data: {
                is_welcome: false,
                data: [],
                users: []
            },
            errors: {
                data: [],
                users: false
            },
            utils: {
                languages: [],
                users: [
                    {
                        name: 'Все пользователи',
                        id: 0
                    }
                ]
            }
        }
    },
    methods: {
        getItem () {
            this.$axios.post(`/messages/item/${this.$props.id}`)
            .then((response) => {
                this.preloader = false
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        
                        this.data.is_welcome = response.data.is_welcome

                        if(response.data.users.length == 1 && response.data.users[0] == 0) {
                            this.data.users.push({
                                name: 'Все пользователи',
                                id: 0
                            })
                        } else {
                            this.getUsers(response.data.users)
                        }
            

                        for (let item of this.data.data) {
                            if(response.data.message[item.language]) {
                                let index = this.data.data.findIndex(row => row.language == item.language)
                                this.data.data[index].title = response.data.message[item.language].title
                                this.data.data[index].text = response.data.message[item.language].text
                            }
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    } else {
                        this.$toast.error(response.message)
                    }
                }

                if(error.response.status == 404) {
                    this.$router.push({name: 'MessagesItems'})
                }
            })
        },
        updateItem () {
            if(!this.data.users || !this.data.users.length) {
                this.errors.users = 'Это поле обязательно'
                return
            }

            for (var i = 0; i < this.data.data.length; i++) {
                if (!this.data.data[i].title) {
                    this.errors.data[i].title = 'Это поле обязательно'
                    return
                }

                if (!this.data.data[i].text) {
                    this.errors.data[i].text = 'Это поле обязательно'
                    return
                }

            }

            var users = []

            for (let user of this.data.users) {
                users.push(user.id)
            }
            
            this.btn_preloader = true
            this.$axios.post('/messages/update', JSON.stringify({
                id: this.$props.id,
                users: users,
                data: this.data.data,
                is_welcome: this.data.is_welcome
            }))
            .then((response) => {
                this.btn_preloader = false
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        this.$toast.success(response.message)

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                this.btn_preloader = false
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errorByPatch(this.errors, response.field, response.message)
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })
        },
        async getLanguages () {
            await this.$axios.post('utils/languages/items')
            .then((response) => {
                this.preloader = false
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        
                        for (let item in response.data) {
                            this.utils.languages.push({
                                name: response.data[item],
                                key: item
                            })

                            this.data.data.push({
                                language: item,
                                title: null,
                                text: null,
                            })

                            this.errors.data.push({
                                title: null,
                                text: null,
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })
        },
        searchUsers (search, loading) {
            this.utils.users = []
            if(search.length >= 3) {
                loading(true)
                 this.$axios.post('/users/search/identificator', JSON.stringify({
                    search: search
                 }))
                .then((response) => {
                    response = response.data
                    switch (response.status) {
                        case "error":
                            this.$toast.error(response.message)
                            break
                        case "success":
                            loading(false)
                            this.utils.users = []
                            for (let user of response.data) {
                                this.utils.users.push({
                                    name: user.email,
                                    id: user.id
                                })
                            }

                            this.setUser(response.user)
                            break
                        default:
                            this.$toast.error('Unknown error')
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if('status' in response && response.status == 'error') {
                        if('field' in response) {
                            this.errors[response.field] = response.message
                        } else {
                            this.$toast.success(response.message)
                        }
                    }
                })
            } else {
                this.utils.users.push({
                    name: 'Все пользователи',
                    id: 0
                })
            }
        },
        getUsers (ids) {
             this.$axios.post('/users/search/ids', JSON.stringify({
                users: ids
             }))
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        for (let user of response.data) {
                            this.data.users.push({
                                id: user.id,
                                name: user.email
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })
        },
        selectUser() {
            for (let user of this.data.users) {
                if(user.id == 0) {
                    this.data.users = [
                        {
                            name: 'Все пользователи',
                            id: 0
                        }
                    ]
                    break
                }
            }
        },
        errorByPatch (layer, path, value) {
            path = path.split('.')

            path.forEach((value, index) => {
                if (!isNaN(value)) {
                    path[index] = parseInt(value)
                }
            })

            for (var i = 0; i < path.length; i++) {
                if (value != null && i + 1 === path.length) {
                    layer[path[i]] = value
                }
                layer = layer[path[i]]
            }
            return layer
        }
    },
    async beforeMount() {
        await this.getLanguages()
        this.getItem()
    }
}
</script>

<style lang="css" scoped>
    ::v-deep .vs__clear {
        display: none;
    }
</style>
