export default {
  ApplicationNAME: 'Tree-X Admin',
  ApplicationDomainAPI: 'https://treex-api.online',
  ApplicationAPI: 'https://treex-api.online/api/v1/admin',
  ApplicationGamesAPI: 'https://games.treex-api.online/api/v1',
  ApplicationURL: 'https://treex-admin.online',
  Langs: ['ru', 'en'],
  RecaptchaV3: {
    token: '6Le9DQUoAAAAAC-kTrdU6zlFPZa56JU3xj21UAwM',
    action: 'casino'
  },
  RecaptchaV2: {
    token: '6Le9DQUoAAAAAC7yWYzCoxVn_Ftx2do_sYYfs0nH'
  },
  Timezone: 'Europe/Moscow'
}
