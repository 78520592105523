<template>
    <main class="main">
        <div class="container-fluid px-0" data-layout="container">

            <aside-main/>

            <header-main/>

            <div class="content">

                <div class="row g-3">
                    <div class="col-sm-6 col-md-6">
                        <h2 class="text-bold text-1100 mb-5">
                            {{utils.types[$props.type]}}
                            <span class="fw-normal text-700 ms-2">({{items_count}})</span>
                        </h2>
                    </div>
                    <div class="col-sm-6 col-md-6 create-button-block">
                        <router-link
                            :to="{name: 'MessagesCreate'}"
                            class="btn btn-primary me-1 mb-1 float_right">
                            Создать
                        </router-link>
                    </div>
                </div>


                <div class="card-body p-0 spinner-center" v-if="preloader">
                    <div class="p-4 code-to-copy">
                        <div class="spinner-border" role="status"></div>
                    </div>
                </div>
                

                <div class="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-white border-y border-300 mt-2 position-relative top-1" v-show="!preloader">
                    <div class="table-responsive scrollbar ms-n1 ps-1" style="padding-bottom: 5px">
                        
                        <div class="table table-responsive-small">
                            <datatable
                                :filter="search.search_change"
                                :columns="columns" 
                                :data="getItems"
                                :per-page="pager.per_page">
                                <template scope="{ row, columns }">
                                    <tr>
                                        <td>{{ row.id }}</td>
                                        <td>{{ row.title }}</td>
                                        <td>{{ row.message }}</td>
                                        <td>{{ row.readed_count }}</td>
                                        <td>{{ $moment(row.created_at).format('YYYY-MM-DD H:mm:ss') }}</td>
                                        <td>
                                            <router-link
                                                :to="{name: 'MessagesItem', params: {id: row.id}}"
                                                content="Редактировать" 
                                                v-tippy="{arrow : true, arrowType: 'round'}"
                                                class="btn btn-soft-primary btn-sm me-1 mb-1">
                                                <i class="fa-regular fa-pen-to-square"></i>
                                            </router-link>

                                            <button
                                                @click="deleteItem(row.id)"
                                                content="Удалить" 
                                                v-tippy="{arrow : true, arrowType: 'round'}"
                                                class="btn btn-soft-danger btn-sm me-1 mb-1">
                                                <i class="fa-regular fa-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </template>
                            </datatable>
                        </div>

                        <div class="col-auto d-flex">
                            <ul class="mb-3 pagination">
                                <datatable-pager v-model="pager.page" type="abbreviated"></datatable-pager>
                            </ul>
                        </div>

                    </div>
                </div>
                
                <footer-main/>

            </div>

        </div>
    </main>
</template>

<script>
export default {
    props: ['type'],
    data () {
        return {
            preloader: true,
            pager: {
                per_page: 25,
                page: 0
            },
            items_count: 0,
            search: {
                search_change: null,
                user_identificator: null
            },
            columns: [
                {label: 'ID', field: 'id', sortable: true},
                {label: 'Заголовок'},
                {label: 'Текст'},
                {label: 'Прочитали'},
                {label: 'Дата', field: 'created_at', sortable: true},
            ],
            utils: {
                types: {
                    start: 'Приветственные',
                    regular: 'История'
                }
            }
        }
    },
    methods: {
        async getItems ({ sortBy, sortDir, perPage, page }) {
            this.preloader = true

            var items = await this.$axios.post('/messages/items', JSON.stringify({
                type: this.$props.type,
                pager: {
                    per_page: this.pager.per_page,
                    page: page - 1
                },
                sort: {
                    by: sortBy,
                    dir: sortDir
                },
                search: {
                    user_identificator: this.search.user_identificator
                }
            }))
            .then((response) => {
                this.preloader = false
                this.setUser(response.data.user)
                return response.data
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })

            if(items && items.status == 'success') {
                this.items_count = items.data.count
                return {
                    rows: items.data.items,
                    totalRowCount: items.data.count
                }
            }

            return {
                rows: [],
                totalRowCount: 0
            }
        },
        deleteItem (id) {
            this.$confirm('Вы уверены что хотите удалить сообщение?').then(() => {
                this.$axios.post('/messages/delete', JSON.stringify({
                    id: id
                }))
                .then((response) => {
                    response = response.data
                    switch (response.status) {
                        case "error":
                            this.$toast.error(response.message)
                            break
                        case "success":
                            this.changeSearch()
                            this.$toast.success(response.message)

                            this.setUser(response.user)
                            break
                        default:
                            this.$toast.error('Unknown error')
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if('status' in response && response.status == 'error') {
                        this.$toast.success(response.message)
                    }
                })
            })
        },
        changeSearch () {
            this.search.search_change = String(Date.now())
        }
    },
    watch: {
      type: {
        async handler (val, oldVal) {
            this.changeSearch()
        }
      }
    }
}
</script>
