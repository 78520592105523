<template>
    <main class="main">
        <div class="container-fluid px-0" data-layout="container">

            <aside-main/>

            <header-main/>

            <div class="content">

                <div class="card-body p-0 spinner-center" v-if="preloader">
                    <div class="p-4 code-to-copy">
                        <div class="spinner-border" role="status"></div>
                    </div>
                </div>

                <div class="row justify-content-between align-items-end g-3 mb-5">
                    <div class="col-12 col-sm-auto col-xl-8">
                        <h2>Баннер {{ data.name }}</h2>
                    </div>
                </div>
                <div class="row g-5" v-if="!preloader">
                    <div class="col-xl-12 mb-5">
                        <form class="row g-3" v-on:submit.prevent="updateItem">
                            <div class="col-sm-6 col-md-12">
                                <div class="form-floating">
                                    <input
                                        v-model="data.name"
                                        @input="errors.name = null"
                                        :class="{'is-invalid': errors.name}"
                                        placeholder="Название"
                                        class="form-control" 
                                        type="text"/>
                                    <label>Название</label>
                                    <div class="invalid-feedback">{{errors.name}}</div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-md-6">
                                <div class="form-floating v-select-medium" :class="{'v-select-error': errors.language}">
                                    <vue-select
                                        @input="errors.language = null"
                                        v-model="data.language"
                                        :options="utils.languages" 
                                        placeholder="Язык"
                                        label="name">
                                        <template v-slot:no-options="{ search, searching }">
                                          <template v-if="searching">
                                             Ничего не найдено по запросу <em>{{ search }}</em>.
                                          </template>
                                        </template>
                                    </vue-select>
                                    <label>Язык</label>
                                    <div class="invalid-feedback">{{errors.language}}</div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-md-6">
                                <div class="form-floating v-select-medium" :class="{'v-select-error': errors.status}">
                                    <vue-select
                                        @input="errors.status = null"
                                        v-model="data.status"
                                        :options="utils.statuses" 
                                        placeholder="Статус"
                                        label="name">
                                        <template v-slot:no-options="{ search, searching }">
                                          <template v-if="searching">
                                             Ничего не найдено по запросу <em>{{ search }}</em>.
                                          </template>
                                        </template>
                                    </vue-select>
                                    <label>Статус</label>
                                    <div class="invalid-feedback">{{errors.status}}</div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-md-6">
                                <div class="form-floating banner-input-raw">
                                    <div 
                                        @click="$refs.imageSmall.click()"
                                        content="Выберите изображение" 
                                        v-tippy="{arrow : true, arrowType: 'round'}"
                                        class="banner-input-number">
                                        <i class="fa-solid fa-plus vertical-center-item"></i>
                                    </div>
                                    <input
                                        v-model="data.images.small.name"
                                        @input="errors.image_small = null"
                                        :class="{'is-invalid': errors.image_small}"
                                        disabled
                                        placeholder="Превью 210x50"
                                        class="form-control" 
                                        type="text"/>
                                    <label>Превью 210x50</label>
                                    <input ref="imageSmall" type="file" @input="imageSmall" accept=".png, .jpg, .jpeg, .gif" style="display: none;">
                                    <button
                                        @click="deleteImageSmall"
                                        content="Удалить" 
                                        v-tippy="{arrow : true, arrowType: 'round'}"
                                        type="button" 
                                        class="btn btn-soft-danger banner-input-button-right">
                                        <span class="vertical-center-item">
                                            <i class="fa-regular fa-trash"></i>
                                        </span>
                                    </button>
                                </div>
                                <div class="invalid-feedback">{{errors.image_small}}</div>
                            </div>

                            <div class="col-sm-6 col-md-6">
                                <div class="form-floating banner-input-raw">
                                    <div 
                                        @click="$refs.imageBig.click()"
                                        content="Выберите изображение" 
                                        v-tippy="{arrow : true, arrowType: 'round'}"
                                        class="banner-input-number">
                                        <i class="fa-solid fa-plus vertical-center-item"></i>
                                    </div>
                                    <input
                                        v-model="data.images.big.name"
                                        @input="errors.image_big = null"
                                        :class="{'is-invalid': errors.image_big}"
                                        disabled
                                        placeholder="Изображение 1920x460"
                                        class="form-control" 
                                        type="text"/>
                                    <label>Изображение 1920x460</label>
                                    <input ref="imageBig" type="file" @input="imageBig" accept=".png, .jpg, .jpeg, .gif" style="display: none;">
                                    <button
                                        @click="deleteImageBig"
                                        content="Удалить" 
                                        v-tippy="{arrow : true, arrowType: 'round'}"
                                        type="button" 
                                        class="btn btn-soft-danger banner-input-button-right">
                                        <span class="vertical-center-item">
                                            <i class="fa-regular fa-trash"></i>
                                        </span>
                                    </button>
                                </div>
                                <div class="invalid-feedback">{{errors.image_big}}</div>
                            </div>                          

                            <div class="col-sm-3 col-md-3">
                                <button class="btn btn-primary px-5 w-100 text-nowrap" type="submit" :class="{'disabled': btn_preloader}">
                                    <span class="spinner-border spinner-border-sm spinner-border-custom" role="status" v-if="btn_preloader"></span>
                                    <span>Сохранить</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                
                <footer-main/>

            </div>

        </div>
    </main>
</template>

<script>
export default {
    props: ['id'],
    data () {
        return {
            btn_preloader: false,
            preloader: true,
            data: {
                name: null,
                language: null,
                status: {
                    name: 'Отключен',
                    key: 0
                },
                images: {
                    small: {
                        name: null,
                        file: null
                    },
                    big: {
                        name: null,
                        file: null
                    }
                }
            },
            errors: {
                name: false,
                language: false,
                status: false,
                image_small: false,
                image_big: false
            },
            utils: {
                languages: [],
                statuses: [
                    {
                        name: 'Включен',
                        key: 1
                    },
                    {
                        name: 'Отключен',
                        key: 0
                    }
                ]
            }
        }
    },
    methods: {
        getItem () {
            this.$axios.post(`/banners/item/${this.$props.id}`)
            .then((response) => {
                this.preloader = false
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        this.data = {
                            name: response.data.name,
                            language: this.utils.languages.find(row => row.key == response.data.language),
                            status: this.utils.statuses.find(row => row.key == response.data.status),
                            images: {
                                small: {
                                    name: response.data.image_small,
                                    file: null
                                },
                                big: {
                                    name: response.data.image_big,
                                    file: null
                                }
                            }
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    } else {
                        this.$toast.error(response.message)
                    }
                }

                if(error.response.status == 404) {
                    this.$router.push({name: 'BannersList'})
                }
            })
        },
        updateItem () {
            if(!this.data.name) {
                this.errors.name = 'Это поле обязательно'
                return
            }
            if(!this.data.language) {
                this.errors.language = 'Это поле обязательно'
                return
            }
            if(!this.data.status) {
                this.errors.status = 'Это поле обязательно'
                return
            }
            
            this.btn_preloader = true

            var formData = new FormData()
            formData.append('id', this.$props.id)
            formData.append('name', this.data.name)
            formData.append('language', this.data.language.key)
            formData.append('status', this.data.status.key)
            formData.append('image_small', this.data.images.small.file || '')
            formData.append('image_big', this.data.images.big.file || '')

            this.$axios.post('/banners/update', formData)
            .then((response) => {
                this.btn_preloader = false
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        this.$toast.success(response.message)

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                this.btn_preloader = false
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })
        },
        async getLanguages () {
            await this.$axios.post('utils/languages/items')
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        
                        for (let item in response.data) {
                            this.utils.languages.push({
                                name: response.data[item],
                                key: item
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    } else {
                        this.$toast.error(response.message)
                    }
                }

                if(error.response.status == 404) {
                    this.$router.push({name: 'BannersList'})
                }
            })
        },
        deleteImageSmall () {
            this.data.images.small.name = null
            this.data.images.small.file = null
        },
        deleteImageBig () {
            this.data.images.big.name = null
            this.data.images.big.file = null
        },
        imageSmall (e) {
            var file = e.target.files[0]

            if(file) {
                this.data.images.small.name = null

                if (file.size > 5000000) {
                    this.errors.image_small = 'Размер изображения не должен превышать 5MB'
                    return
                }

                var th = this
                var reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = (e) => {
                    var image = new Image()
                    image.src = e.target.result
                    image.onload = () => {
                      var {height, width} = image
                      if (height != 50 || width != 210) {
                        th.errors.image_small = 'Размер должен быть 210x50'
                        return false
                      }
                      th.errors.image_small = false
                      return true
                    }
                }

                this.data.images.small.name = file.name
                this.data.images.small.file = file
            }
        },
        imageBig (e) {
            var file = e.target.files[0]

            if(file) {
                this.data.images.big.name = null

                if (file.size > 5000000) {
                    this.errors.image_big = 'Размер изображения не должен превышать 5MB'
                    return
                }

                var th = this
                var reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = (e) => {
                    var image = new Image()
                    image.src = e.target.result
                    image.onload = () => {
                      var {height, width} = image
                      if (height < 460 || width < 1920) {
                        th.errors.image_big = 'Размер должен быть 1920x460 или больше'
                        return false
                      }
                      th.errors.image_big = false
                      return true
                    }
                }

                this.data.images.big.name = file.name
                this.data.images.big.file = file
            }
        }
    },
    async beforeMount() {
        await this.getLanguages()
        this.getItem()
    }
}
</script>

<style lang="css" scoped>
    ::v-deep .vs__clear {
        display: none;
    }
</style>
