<template>
    <main class="main">
        <div class="container-fluid px-0" data-layout="container">

            <aside-main/>

            <header-main/>

            <div class="content">
                <div class="row justify-content-between align-items-end g-3 mb-5">
                    <div class="col-12 col-sm-auto col-xl-8">
                        <h2>Создать Товар</h2>
                    </div>
                </div>

                <div class="row g-5">
                    <div class="col-xl-12 mb-5">
                        <form class="row g-3" v-on:submit.prevent="createItem">
                            <div class="col-sm-6 col-md-12">
                                <div class="form-check" style="float:right">
                                    <input 
                                        v-model="data.is_available"
                                        class="form-check-input" 
                                        id="is_available" 
                                        type="checkbox">
                                    <label class="form-check-label" for="is_available">В наличии</label>
                                </div>
                            </div>

                            <div class="col-sm-6 col-md-12">
                                <div class="form-floating">
                                    <input
                                        v-model="data.name"
                                        @input="errors.name = null"
                                        :class="{'is-invalid': errors.name}"
                                        placeholder="Название"
                                        class="form-control" 
                                        type="text"/>
                                    <label>Название</label>
                                    <div class="invalid-feedback">{{errors.name}}</div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-md-6">
                                <div class="form-floating v-select-medium" :class="{'v-select-error': errors.category}">
                                    <vue-select
                                        @input="errors.category = null"
                                        v-model="data.category"
                                        :options="utils.categories" 
                                        placeholder="Категория"
                                        label="name">
                                        <template v-slot:no-options="{ search, searching }">
                                          <template v-if="searching">
                                             Ничего не найдено по запросу <em>{{ search }}</em>.
                                          </template>
                                        </template>
                                    </vue-select>
                                    <label>Категория</label>
                                    <div class="invalid-feedback">{{errors.category}}</div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-md-6">
                                <div class="form-floating">
                                    <input
                                        v-model="data.price"
                                        @input="errors.price = null"
                                        :class="{'is-invalid': errors.price}"
                                        placeholder="Цена"
                                        class="form-control" 
                                        type="Цена"/>
                                    <label>Цена</label>
                                    <div class="invalid-feedback">{{errors.price}}</div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-md-6">
                                <div class="form-floating banner-input-raw">
                                    <div 
                                        @click="$refs.image.click()"
                                        content="Выберите изображение" 
                                        v-tippy="{arrow : true, arrowType: 'round'}"
                                        class="banner-input-number">
                                        <i class="fa-solid fa-plus vertical-center-item"></i>
                                    </div>
                                    <input
                                        v-model="data.image.name"
                                        @input="errors.image = null"
                                        :class="{'is-invalid': errors.image}"
                                        disabled
                                        placeholder="Превью 210x50"
                                        class="form-control" 
                                        type="text"/>
                                    <label>Превью 700x700</label>
                                    <input ref="image" type="file" @input="image" accept=".png, .jpg, .jpeg, .gif" style="display: none;">
                                    <button
                                        @click="deleteImage"
                                        content="Удалить" 
                                        v-tippy="{arrow : true, arrowType: 'round'}"
                                        type="button" 
                                        class="btn btn-soft-danger banner-input-button-right">
                                        <span class="vertical-center-item">
                                            <i class="fa-regular fa-trash"></i>
                                        </span>
                                    </button>
                                </div>
                                <div class="invalid-feedback">{{errors.image}}</div>
                            </div> 

                            <div class="col-sm-6 col-md-6">
                                <div class="form-floating v-select-medium" :class="{'v-select-error': errors.status}">
                                    <vue-select
                                        @input="errors.status = null"
                                        v-model="data.status"
                                        :options="utils.statuses" 
                                        placeholder="Статус"
                                        label="name">
                                        <template v-slot:no-options="{ search, searching }">
                                          <template v-if="searching">
                                             Ничего не найдено по запросу <em>{{ search }}</em>.
                                          </template>
                                        </template>
                                    </vue-select>
                                    <label>Статус</label>
                                    <div class="invalid-feedback">{{errors.status}}</div>
                                </div>
                            </div>                       

                            <div class="col-sm-3 col-md-3">
                                <button class="btn btn-primary px-5 w-100 text-nowrap" type="submit" :class="{'disabled': btn_preloader}">
                                    <span class="spinner-border spinner-border-sm spinner-border-custom" role="status" v-if="btn_preloader"></span>
                                    <span>Создать</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                
                <footer-main/>

            </div>

        </div>
    </main>
</template>

<script>
export default {
    data () {
        return {
            btn_preloader: false,
            data: {
                is_available: true,
                name: null,
                category: null,
                price: null,
                image: {
                    name: null,
                    file: null
                },
                status: {
                    name: 'Отключен',
                    key: 0
                },
            },
            errors: {
                name: false,
                category: false,
                price: false,
                image: false,
                status: false
            },
            utils: {
                categories: [],
                statuses: [
                    {
                        name: 'Включен',
                        key: 1
                    },
                    {
                        name: 'Отключен',
                        key: 0
                    }
                ]
            }
        }
    },
    methods: {
        createItem () {
            if(!this.data.name) {
                this.errors.name = 'Это поле обязательно'
                return
            }
            if(!this.data.category) {
                this.errors.category = 'Это поле обязательно'
                return
            }
            if(!this.data.price) {
                this.errors.price = 'Это поле обязательно'
                return
            }
            if(!this.data.image.file) {
                this.errors.image = 'Это поле обязательно'
                return
            }
            if(!this.data.status) {
                this.errors.status = 'Это поле обязательно'
                return
            }
            
            this.btn_preloader = true

            var formData = new FormData()
            formData.append('is_available', this.data.is_available ? 1 : 0)
            formData.append('name', this.data.name)
            formData.append('category', this.data.category.id)
            formData.append('price', this.data.price)
            formData.append('image', this.data.image.file)
            formData.append('status', this.data.status.key)

            this.$axios.post('/experience/products/create', formData)
            .then((response) => {
                this.btn_preloader = false
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        this.$router.push({name: 'ExperienceProductsItems'})
                        this.$toast.success(response.message)

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                this.btn_preloader = false
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })
        },
        getCategories () {
            this.$axios.post('/experience/products/categories/items', JSON.stringify({
                search: {
                    name: null,
                    status: null
                }
            }))
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        for (let item of response.data) {
                            this.utils.categories.push({
                                name: item.name,
                                id: item.id
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)
                
                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })  
        },
        deleteImage () {
            this.data.image.name = null
            this.data.image.file = null
        },
        image (e) {
            var file = e.target.files[0]

            if(file) {
                this.data.image.name = null

                if (file.size > 5000000) {
                    this.errors.image = 'Размер изображения не должен превышать 5MB'
                    return
                }

                var th = this
                var reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = (e) => {
                    var image = new Image()
                    image.src = e.target.result
                    image.onload = () => {
                      var {height, width} = image
                      if (height != 700 || width != 700) {
                        th.errors.image = 'Размер должен быть 700x700'
                        return false
                      }
                      th.errors.image = false
                      return true
                    }
                }

                this.data.image.name = file.name
                this.data.image.file = file
            }
        }
    },
    beforeMount () {
        this.getCategories()
    }
}
</script>

<style lang="css" scoped>
    ::v-deep .vs__clear {
        display: none;
    }
</style>
