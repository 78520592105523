<template>
    <main class="main">
        <div class="container-fluid px-0" data-layout="container">

        	<aside-main/>

            <header-main/>

            <div class="content px-0 pt-9">
                <div class="row g-0">
                    <div class="col-12 col-xxl-8 px-0 bg-soft">
                        <div class="px-4 px-lg-6 pt-6 pb-9">
                            <div class="mb-5">
                                <div class="d-flex justify-content-between">
                                    <h2 class="text-black fw-bolder mb-2">Главная страница</h2>
                                </div>
                            </div>

                            <div class="card-body p-0 spinner-center" v-if="preloader">
                                <div class="p-4 code-to-copy">
                                    <div class="spinner-border" role="status"></div>
                                </div>
                            </div>

                            <div class="row gx-0 gx-sm-5 gy-8 mb-8" v-show="!preloader">
                                <div class="col-12 col-sm-5 col-lg-4 col-xl-3 col-xxl-4">
                                    <div class="mb-5">
                                        <h4 class="text-black">Топ пользователи</h4>
                                        <h6 class="text-700">Депозиты за 30 дней</h6>
                                    </div>
                                    <div class="echart-top-coupons mb-5">
                                        <div>
                                            <v-chart 
                                                class="chart_top_users" 
                                                :manual-update="true" 
                                                :autoresize="true" 
                                                :option="option_top_users"
                                                ref="chart_top_users"/>
                                        </div>
                                    </div>
                                    <div class="row justify-content-center">
                                        <div class="col-auto col-sm-12">
                                            <div class="row justify-content-center justify-content-sm-between g-5 g-sm-0 mb-2" v-for="(item, index) in top_users">
                                                <div class="col">
                                                    <div class="d-flex align-items-center">
                                                        <div
                                                            v-bind:class="{'bg-primary': index == 0, 'bg-primary-200': index == 1, 'bg-info': index == 2}"
                                                            class="bullet-item me-2"></div>
                                                        <h6 class="text-900 fw-semi-bold flex-1 mb-0">{{ item.user_email }}</h6>
                                                    </div>
                                                </div>
                                                <div class="col-auto">
                                                    <h6 class="text-900 fw-semi-bold mb-0">{{ item.amount }} {{utils.merchant.currency}}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 col-xl-9 col-xxl-8">
                                    <div class="echart-completed-task-chart">
                                        <v-chart 
                                            class="chart_last_users" 
                                            :manual-update="true" 
                                            :autoresize="true" 
                                            ref="chart_last_users"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <footer-main/>

            </div>

        </div>
    </main>
</template>

<script>

export default {
    data () {
        return {
            preloader: true,
            top_users: [],
            users: [],
            utils: {
                merchant: {
                    name: 'Rubbles',
                    currency: 'RUB'
                }
            },
            option_top_users: {
                grid: {
                    containLabel: true
                },
                tooltip: {
                    trigger: 'item',
                    padding: [7, 10],
                    backgroundColor: '#eff2f6',
                    borderColor: '#cbd0dd',
                    textStyle: { color: '#141824' },
                    borderWidth: 1,
                    transitionDuration: 0,
                    formatter: (e) => `<strong>${e.data.name}:</strong> ${e.data.value} ${this.utils.merchant.currency}`,
                },
                legend: {
                    show: false
                },
                series: [
                {
                    type: 'pie',
                    radius: ['100%', '87%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: 'center'
                    },
                    itemStyle: {
                        borderColor: '#f5f7fa',
                        borderWidth: 2
                    },
                    emphasis: { 
                        scale: false, 
                        itemStyle: {
                            color: 'inherit'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                    { 
                        value: 0,
                        name: null,
                        itemStyle: {
                            normal: {
                                color: 'rgb(56, 116, 255)'
                            }
                        }
                    },
                    {
                        value: 0,
                        name: null,
                        itemStyle: {
                            normal: {
                                color: 'rgb(173, 197, 255)'
                            }
                        }
                    },
                    {
                        value: 0,
                        name: null,
                        itemStyle: {
                            normal: {
                                color: 'rgb(0, 151, 235)'
                            }
                        }
                    }
                  ]
                }
              ]
            },
            option_last_users: {
                grid: {
                    width: '98%',
                    right: '1%',
                    left: '1%',
                    containLabel: true
                },
                title: {
                    text: 'График регистраций',
                    subtext: 'За последние 30 дней',
                    textStyle: {
                        color: '#141824'
                    },
                    subtextStyle: {
                        color: 'rgb(110, 120, 145)'
                    }
                },
                tooltip: {
                    trigger: 'axis',
                    padding: 10, 
                    backgroundColor: '#eff2f6', 
                    borderColor: '#cbd0dd', 
                    textStyle: { 
                        color: '#141824'
                    },
                    borderWidth: 1,
                    transitionDuration: 0,
                    axisPointer: {
                        type: 'none'
                    }
                },
                toolbox: {
                    show: true,
                    feature: {
                        saveAsImage: {
                            name: 'chart_' + Date.parse(new Date())/1000,
                            title: 'Сохранить изображение', 
                            type: 'png',
                            iconStyle: {
                                borderColor: 'rgb(110, 120, 145)'
                            },
                            emphasis: {
                                iconStyle: {
                                    borderColor: '#3874ff'
                                }
                            }
                        },
                        magicType: {
                            title: {
                                line: 'Линейный график', 
                                bar: 'Гистограмма', 
                                stack: 'Стек'
                            },
                            iconStyle: {
                                borderColor: 'rgb(110, 120, 145)'
                            },
                            emphasis: {
                                iconStyle: {
                                    borderColor: '#3874ff'
                                }
                            },
                            type: ['line', 'bar', 'stack']
                        }
                    }
                },
                xAxis: [
                    {
                        type: 'category',
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            interval: 0,
                            show: false
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#e0e6f1'
                            },
                            show: true
                        },
                        boundaryGap: false,
                        data: []
                    },
                    {
                        type: 'category',
                        position: 'bottom',
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#e0e6f1'
                            }
                        },
                        axisLabel: {
                            formatter: (e) => {
                                var index = this.option_last_users.xAxis[1].data.findIndex(row => row == e)
                                if(index == 0) {
                                    return `{first|${e}}`
                                } else if(index == this.option_last_users.xAxis[1].data.length - 1) {
                                    return `{last|${e}}`
                                }
                                return e
                            },
                            rich: {
                                first: {
                                    padding: [0,0,0,55]
                                },
                                last: {
                                    padding: [0,55,0,0]
                                }
                            },
                            color: 'rgb(110, 120, 145)'
                        },
                        splitLine: {
                            show: false
                        },
                        boundaryGap: false,
                        data: []
                    }
                ],
                yAxis: {
                    position: 'right', 
                    axisPointer: {
                        type: 'none'
                    }, 
                    axisTick: 'none', 
                    splitLine: {
                        show: false
                    }, 
                    axisLine: {
                        show: false
                    }, 
                    axisLabel: {
                        show: false
                    }
                },
                series: [
                    {
                        lineStyle: {
                            color: 'rgb(0, 151, 235)'
                        },
                        itemStyle: {
                            normal: {
                                color: 'rgb(0, 151, 235)'
                            }
                        },
                        showSymbol: false, 
                        symbol: 'circle',
                        name: 'Зарегистрировано',
                        type: 'line',
                        data: []
                    },
                    {
                        type: 'line',
                        showSymbol: false, 
                        symbol: 'circle',
                        lineStyle: {
                            color: '#3874ff'
                        },
                        itemStyle: {
                            normal: {
                                color: '#3874ff'
                            }
                        },
                        name: 'Email верификация',
                        data: []
                    },
                    {
                        lineStyle: {
                            color: '#e5780b'
                        },
                        itemStyle: {
                            normal: {
                                color: '#e5780b'
                            }
                        },
                        showSymbol: false, 
                        symbol: 'circle',
                        name: 'KYC верификация',
                        type: 'line',
                        data: []
                    }
                ]
            },
            option_test: {
                grid: {
                    containLabel: true
                },
                tooltip: {
                    trigger: 'item',
                    padding: [7, 10],
                    backgroundColor: '#eff2f6',
                    borderColor: '#cbd0dd',
                    textStyle: { color: '#141824' },
                    borderWidth: 1,
                    transitionDuration: 0,
                    //formatter: '<strong>{b}:</strong> {c}%',
                    formatter: (e) => `<strong>${e.data.name}</strong></br>
                    <span class="text-900 fw-semi-bold" style="font-size: 0.7rem">Количество игр: ${e.data.data.games}</span></br>
                    <span class="text-900 fw-semi-bold" style="font-size: 0.7rem">Ставки: ${e.data.data.bets} ${e.data.data.currency}</span></br>
                    <span class="text-900 fw-semi-bold" style="font-size: 0.7rem">Выигрыш: ${e.data.data.win} ${e.data.data.currency}</span></br>
                    <span class="text-900 fw-semi-bold" style="font-size: 0.7rem">Проигрыш: ${e.data.data.loss} ${e.data.data.currency}</span>`,
              },
              legend: {
                show: false
              },
              series: [
                {
                  type: 'pie',
                  radius: ['100%', '87%'],
                  avoidLabelOverlap: false,
                  label: {
                    show: false,
                    position: 'center',
                    //formatter: '{c}'
                  },
                  itemStyle: {
                    borderColor: '#f5f7fa',
                    borderWidth: 2
                  },
                  emphasis: { 
                    scale: false, 
                    itemStyle: {
                        color: 'inherit'
                    }
                  },
                  labelLine: {
                    show: false
                  },
                  data: [
                    { 
                        value: 300, 
                        data: {
                            games: 10,
                            bets: 1500,
                            win: 2000,
                            loss: 255,
                            currency: 'RUB'
                        },
                        name: 'test@mail.com',
                        itemStyle: {
                            normal: {
                                color: 'rgb(56, 116, 255)'
                            }
                        }
                    },
                    {
                        value: 250,
                        data: {
                            games: 10,
                            bets: 1500,
                            win: 2000,
                            loss: 255,
                            currency: 'RUB'
                        },
                        name: 'test2@mail.com',
                        itemStyle: {
                            normal: {
                                color: 'rgb(173, 197, 255)'
                            }
                        }
                    },
                    {
                        value: 376,
                        data: {
                            games: 10,
                            bets: 1500,
                            win: 2000,
                            loss: 255,
                            currency: 'RUB'
                        },
                        name: 'test3@mail.com',
                        itemStyle: {
                            normal: {
                                color: 'rgb(0, 151, 235)'
                            }
                        }
                    }
                  ]
                }
              ]
            }
        }
    },
    methods: {
        getTopUsers () {
            this.preloader = true

            this.$axios.post('/payments/analytics/items', JSON.stringify({
                pager: {
                    per_page: 3,
                    page: 0
                },
                sort: {
                    by: 'amount',
                    dir: 'desc'
                },
                search: {
                    user: null,
                    merchant: 1,
                    type: 'deposit',
                    status: 1,
                    paysystem: null,
                    group_by: 'user_id',
                    date: {
                        start: this.$moment().set({date: this.$moment().get('date') - 30}).format('Y-MM-DD HH:mm:ss'),
                        end: this.$moment().format('Y-MM-DD HH:mm:ss')
                    }
                }
            }))
            .then((response) => {
                response = response.data
                this.preloader = false
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        this.top_users = response.data.items

                        for (let [index, item] of this.top_users.entries()) {
                            this.option_top_users.series[0].data[index].name = item.user_email
                            this.option_top_users.series[0].data[index].value = item.amount
                        }

                        this.$refs.chart_top_users.setOption(this.option_top_users)

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        getLastUsers () {
            this.$axios.post('/users/analytics/items', JSON.stringify({
                pager: {
                    per_page: 31,
                    page: 0
                },
                sort: {
                    by: 'date',
                    dir: 'asc'
                },
                search: {
                    date: {
                        start: this.$moment().set({date: this.$moment().get('date') - 30}).format('Y-MM-DD HH:mm:ss'),
                        end: this.$moment().format('Y-MM-DD HH:mm:ss')
                    }
                }
            }))
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        this.users = response.data.items

                        if(this.users.length) {
                            for (let item of this.users) {
                                this.option_last_users.series[0].data.push(item.total)
                                this.option_last_users.series[1].data.push(item.email)
                                this.option_last_users.series[2].data.push(item.kyc)

                                this.option_last_users.xAxis[0].data.push(this.$moment(item.date).format('DD MMMM'))
                            }

                            this.option_last_users.xAxis[1].data.push(this.option_last_users.xAxis[0].data[0])
                            this.option_last_users.xAxis[1].data.push(this.option_last_users.xAxis[0].data[Math.floor((this.option_last_users.xAxis[0].data.length - 1) / 2)])
                            this.option_last_users.xAxis[1].data.push(this.option_last_users.xAxis[0].data[this.option_last_users.xAxis[0].data.length - 1])
                        }

                        this.$refs.chart_last_users.setOption(this.option_last_users)

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)
                
                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
    },
    computed: {
        changeTheme() {
            return this.$store.state.theme
        }
    },
    watch: {
        changeTheme (newTheme, oldTheme) {
            if(newTheme == 'dark') {
                this.option_top_users.series[0].itemStyle.borderColor = '#0f111a'
                this.option_top_users.tooltip.backgroundColor = 'rgb(34, 40, 52)'
                this.option_top_users.tooltip.borderColor = 'rgb(55, 62, 83)'
                this.option_top_users.tooltip.textStyle.color = 'rgb(239, 242, 246)'

                this.option_last_users.title.textStyle.color = '#e3e6ed'
                this.option_last_users.xAxis[0].splitLine.lineStyle.color = '#313749'
                this.option_last_users.xAxis[1].axisLine.lineStyle.color = '#313749'
                this.option_last_users.tooltip.backgroundColor = 'rgb(34, 40, 52)'
                this.option_last_users.tooltip.borderColor = 'rgb(55, 62, 83)'
                this.option_last_users.tooltip.textStyle.color = 'rgb(239, 242, 246)'
            } else {
                this.option_top_users.series[0].itemStyle.borderColor = '#f5f7fa'
                this.option_top_users.tooltip.backgroundColor = '#eff2f6'
                this.option_top_users.tooltip.borderColor = '#cbd0dd'
                this.option_top_users.tooltip.textStyle.color = '#141824'

                this.option_last_users.title.textStyle.color = '#141824'
                this.option_last_users.xAxis[0].splitLine.lineStyle.color = '#e0e6f1'
                this.option_last_users.xAxis[1].axisLine.lineStyle.color = '#e0e6f1'
                this.option_last_users.tooltip.backgroundColor = '#eff2f6'
                this.option_last_users.tooltip.borderColor = '#cbd0dd'
                this.option_last_users.tooltip.textStyle.color = '#141824'
            }
            this.$refs.chart_top_users.setOption(this.option_top_users)
            this.$refs.chart_last_users.setOption(this.option_last_users)
        }
    },
    beforeMount() {
        this.getTopUsers()
        this.getLastUsers()
    }
}
</script>

<style lang="css" scoped>
    ::v-deep #merchant_block .vs__clear {
        display: none;
    }
    ::v-deep .chart_top_users {
        height:115px;
        width:100%;
    }
    ::v-deep .chart_last_users {
        height: 350px;
    }
</style>