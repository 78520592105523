<template>
    <main class="main">
        <div class="container-fluid px-0" data-layout="container">

            <aside-main/>

            <header-main/>

            <div class="content">
                <form class="mb-9" v-on:submit.prevent="createItem">
                    <div class="row justify-content-between align-items-end g-3 mb-5">
                        <div class="col-12 col-sm-auto col-xl-8">
                            <h2>Создать промо акцию</h2>
                        </div>
                        <div class="col-12 col-sm-auto col-xl-4">
                            <div class="d-flex">
                                <button
                                    :class="{'disabled': btn_preloader}"
                                    class="btn btn-primary px-5 w-100 text-nowrap" 
                                    type="submit">
                                    <span class="spinner-border spinner-border-sm spinner-border-custom" role="status" v-if="btn_preloader"></span>
                                    <span>Создать</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="row g-5">
                        <div class="col-xl-12">

                            <h4 class="fs-1 mb-4">Основная информация</h4>

                            <div class="row g-3">
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.user}">
                                        <vue-select
                                            @input="errors.user = null, getUserMerchant()"
                                            @search="searchUsers"
                                            v-model="data.user"
                                            :options="utils.users" 
                                            placeholder="Пользователь"
                                            label="email">
                                            <template v-slot:no-options="{ search, searching }">
                                                <template v-if="searching && search.length >= 3">
                                                    Ничего не найдено по запросу <em>{{ search }}</em>.
                                                </template>
                                                <span v-else>Введите не менее 3 символов</span>
                                            </template>
                                        </vue-select>
                                        <label>Пользователь</label>
                                        <div class="invalid-feedback">{{errors.user}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <date-range-picker
                                            :class="{'is-invalid': errors.date}"
                                            @update="errors.date = null"
                                            :locale-data="datepiker"
                                            :opens="pikerparams.opens"
                                            :always-show-calendars="pikerparams.showcalendars"
                                            :close-on-esc="pikerparams.closeonesc"
                                            :auto-apply="pikerparams.autoapply"
                                            :time-picker="pikerparams.timePicker"
                                            :time-picker24-hour="pikerparams.timePicker24Hour"
                                            v-model="data.date">
                                            <template #ranges="ranges">
                                              <div class="ranges">
                                                <ul>
                                                  <li 
                                                    v-for="(range, name, key) in pikerranges" 
                                                    :key="name" 
                                                    @click="ranges.clickRange(range, key)">
                                                        {{name}}
                                                  </li>
                                                </ul>
                                              </div>
                                            </template>
                                        </date-range-picker>

                                        <label>Период проведения</label>
                                        <div class="invalid-feedback">{{errors.date}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.wager"
                                            @input="errors.wager = null"
                                            :class="{'is-invalid': errors.wager}"
                                            placeholder="Wager"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Wager</label>
                                        <div class="invalid-feedback">{{errors.wager}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.type}">
                                        <vue-select
                                            @input="errors.type = null, changeType()"
                                            v-model="data.type"
                                            :options="utils.types" 
                                            placeholder="Тип"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Тип</label>
                                        <div class="invalid-feedback">{{errors.type}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.action}">
                                        <vue-select
                                            @input="errors.action = null, changeAction()"
                                            v-model="data.action"
                                            :options="utils.actions" 
                                            placeholder="Действие"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Действие</label>
                                        <div class="invalid-feedback">{{errors.action}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.method}">
                                        <vue-select
                                            @input="errors.method = null"
                                            v-model="data.method"
                                            :options="utils.methods" 
                                            placeholder="Method"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Method</label>
                                        <div class="invalid-feedback">{{errors.method}}</div>
                                    </div>
                                </div>


                                <h4 class="fs-1 mt-5">Настройка промо акции</h4>

                                <template v-if="data.type.key == 1 && utils.merchant && refresh_merchant_data">

                                    <div class="col-sm-6 col-md-4" :class="{'col-md-6': data.action.key == 2}">
                                        <div class="form-floating v-select-medium">
                                            <vue-select
                                                disabled
                                                v-model="utils.merchant.name"
                                                label="name">
                                                <template v-slot:no-options="{ search, searching }">
                                                  <template v-if="searching">
                                                     Ничего не найдено по запросу <em>{{ search }}</em>.
                                                  </template>
                                                </template>
                                            </vue-select>
                                            <label>Мерчант</label>
                                        </div>
                                    </div>

                                    <div class="col-sm-6 col-md-4" v-if="data.action.key != 2">
                                        <div class="form-floating">
                                            <input
                                                v-model="data.merchant.min_deposit"
                                                @input="errors.merchant.min_deposit = null, refreshMerchantData()"
                                                :class="{'is-invalid': errors.merchant.min_deposit}"
                                                placeholder="Мин. сумма депозита"
                                                class="form-control" 
                                                type="text"/>
                                            <label>Мин. сумма депозита</label>
                                            <div class="invalid-feedback">{{ errors.merchant.min_deposit }}</div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-6 col-md-4" :class="{'col-md-6': data.action.key == 2}">
                                        <div class="form-floating">
                                            <input
                                                v-model="data.merchant.amount"
                                                @input="errors.merchant.amount = null, refreshMerchantData()"
                                                :class="{'is-invalid': errors.merchant.amount}"
                                                placeholder="Сумма начисления"
                                                class="form-control" 
                                                type="text"/>
                                            <label>Сумма начисления</label>
                                            <div class="invalid-feedback">{{ errors.merchant.amount }}</div>
                                        </div>
                                    </div>
                                </template>

                                <template v-else-if="data.type.key == 2 && utils.merchant && refresh_merchant_data">

                                    <div class="col-sm-6 col-md-6">
                                        <div class="form-floating v-select-medium" :class="{'v-select-error': errors.game}">
                                            <vue-select
                                                @input="errors.game = null"
                                                @search="searchGames"
                                                v-model="data.game"
                                                :options="utils.games" 
                                                placeholder="Игра"
                                                label="name">
                                                <template v-slot:option="option">
                                                    <img style="width: 50px;margin-right: 20px;height: 100%; height: 35px;" :src="option.image"/>
                                                    <span>{{ option.name }}</span>
                                                    <template v-if="option.is_mobile">
                                                        <span class="badge badge-casino fs--3 badge-casino-warning" style="float: right;margin-top: 10px;margin-left: 10px;">Mobile</span>
                                                    </template>
                                                    <template v-if="option.is_desktop">
                                                        <span class="badge badge-casino fs--3 badge-casino-success" style="float: right;margin-top: 10px;margin-left: 10px;">Desktop</span>
                                                    </template>
                                                </template>
                                                <template v-slot:no-options="{ search, searching }">
                                                    <template v-if="searching && search.length >= 3">
                                                        Ничего не найдено по запросу <em>{{ search }}</em>.
                                                    </template>
                                                    <span v-else>Введите не менее 3 символов</span>
                                                </template>
                                            </vue-select>
                                            <label>Игра</label>
                                            <div class="invalid-feedback">{{errors.game}}</div>
                                        </div>
                                    </div>

                                    <div class="col-sm-6 col-md-6">
                                        <div class="form-floating">
                                            <input
                                                v-model="data.freespins_number"
                                                @input="errors.freespins_number = null"
                                                :class="{'is-invalid': errors.freespins_number}"
                                                placeholder="Количество врощений"
                                                class="form-control" 
                                                type="text"/>
                                            <label>Количество врощений</label>
                                            <div class="invalid-feedback">{{errors.freespins_number}}</div>
                                        </div>
                                    </div>

                                    <template v-if="data.game && data.freespins_number > 0 && refresh_merchant_data">
                                        <div class="col-sm-6 col-md-4" :class="{'col-md-6': data.action.key == 2}">
                                            <div class="form-floating v-select-medium">
                                                <vue-select
                                                    disabled
                                                    v-model="utils.merchant.name"
                                                    label="name">
                                                    <template v-slot:no-options="{ search, searching }">
                                                      <template v-if="searching">
                                                         Ничего не найдено по запросу <em>{{ search }}</em>.
                                                      </template>
                                                    </template>
                                                </vue-select>
                                                <label>Мерчант</label>
                                            </div>
                                        </div>

                                        <div class="col-sm-6 col-md-4" v-if="data.action.key != 2">
                                            <div class="form-floating">
                                                <input
                                                    v-model="data.merchant.min_deposit"
                                                    @input="errors.merchant.min_deposit = null, refreshMerchantData()"
                                                    :class="{'is-invalid': errors.merchant.min_deposit}"
                                                    placeholder="Мин. сумма депозита"
                                                    class="form-control" 
                                                    type="text"/>
                                                <label>Мин. сумма депозита</label>
                                                <div class="invalid-feedback">{{ errors.merchant.min_deposit }}</div>
                                            </div>
                                        </div>

                                        <div class="col-sm-6 col-md-4" :class="{'col-md-6': data.action.key == 2}">
                                            <div class="form-floating v-select-medium" :class="{'v-select-error': errors.merchant.bet}">
                                                <vue-select
                                                    @open="openBetMerchant(utils.merchant)"
                                                    @input="errors.merchant.bet = null, refreshMerchantData()"
                                                    v-model="data.merchant.bet"
                                                    :options="utils.freespins_bets" 
                                                    placeholder="Сумма бета"
                                                    label="total_bet_per_line">
                                                    <template v-slot:option="option">
                                                        <span>{{ option.total_bet_per_line }}</span>
                                                    </template>
                                                    <template v-slot:no-options="{ search, searching }">
                                                      <template v-if="searching">
                                                         Ничего не найдено по запросу <em>{{ search }}</em>.
                                                      </template>
                                                      <template v-else>
                                                         Идет поиск бетов под мерчант <em>{{utils.merchant.name}}</em>.
                                                      </template>
                                                    </template>
                                                </vue-select>
                                                <label>Сумма бета</label>
                                                <div class="invalid-feedback">{{ errors.merchant.bet }}</div>
                                            </div>
                                        </div>
                                    </template>
                                </template>

                                <template v-if="data.type.key == 3 && utils.merchant && refresh_merchant_data">

                                    <div class="col-sm-6 col-md-12">
                                        <div class="form-floating">
                                            <input
                                                v-model="data.percent"
                                                @input="errors.percent = null"
                                                :class="{'is-invalid': errors.percent}"
                                                placeholder="Процент"
                                                class="form-control" 
                                                type="text"/>
                                            <label>Процент</label>
                                            <div class="invalid-feedback">{{errors.percent}}</div>
                                        </div>
                                    </div>

                                    <div class="col-sm-6 col-md-4">
                                        <div class="form-floating v-select-medium">
                                            <vue-select
                                                disabled
                                                v-model="utils.merchant.name"
                                                label="name">
                                                <template v-slot:no-options="{ search, searching }">
                                                  <template v-if="searching">
                                                     Ничего не найдено по запросу <em>{{ search }}</em>.
                                                  </template>
                                                </template>
                                            </vue-select>
                                            <label>Мерчант</label>
                                        </div>
                                    </div>

                                    <div class="col-sm-6 col-md-4">
                                        <div class="form-floating">
                                            <input
                                                v-model="data.merchant.min_deposit"
                                                @input="errors.merchant.min_deposit = null, refreshMerchantData()"
                                                :class="{'is-invalid': errors.merchant.min_deposit}"
                                                placeholder="Мин. сумма депозита"
                                                class="form-control" 
                                                type="text"/>
                                            <label>Мин. сумма депозита</label>
                                            <div class="invalid-feedback">{{ errors.merchant.min_deposit }}</div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-6 col-md-4">
                                        <div class="form-floating">
                                            <input
                                                v-model="data.merchant.max_deposit"
                                                @input="errors.merchant.max_deposit = null, refreshMerchantData()"
                                                :class="{'is-invalid': errors.merchant.max_deposit}"
                                                placeholder="Макс. сумма депозита"
                                                class="form-control" 
                                                type="text"/>
                                            <label>Макс. сумма депозита</label>
                                            <div class="invalid-feedback">{{ errors.merchant.max_deposit }}</div>
                                        </div>
                                    </div>
                                </template>

                            </div>
                        </div>
                    </div>
                </form>
                
                <footer-main/>

            </div>

        </div>
    </main>
</template>

<script>
export default {
    data () {
        return {
            btn_preloader: false,
            refresh_merchant_data: null,
            data: {
                user: null,
                wager: null,
                type: {
                    name: 'Ваучер',
                    key: 1
                },
                action: {
                    name: 'За депозит',
                    key: 1
                },
                method: {
                    name: 'Бонусный баланс',
                    key: 0
                },
                status: {
                    name: 'В ожидании',
                    key: 0
                },
                date: {
                    startDate: this.$moment().format('Y-MM-DD HH:mm:ss'),
                    endDate: this.$moment().set({date: this.$moment().get('date') + 30}).format('Y-MM-DD HH:mm:ss')
                },
                merchant: {},
                game: null,
                freespins_number: null,
                percent: null
            },
            errors: {
                wager: false,
                user: false,
                type: false,
                action: false,
                method: false,
                status: false,
                date: false,
                merchant: {},
                game: false,
                freespins_number: false,
                percent: false
            },
            utils: {
                merchant: null,
                users: [],
                games: [],
                freespins_bets: [],
                types: [
                    {
                        name: 'Ваучер',
                        key: 1
                    },
                    {
                        name: 'Бесплатные вращения',
                        key: 2
                    },
                    {
                        name: 'Кэш Бонус',
                        key: 3
                    }
                ],
                actions: [
                    {
                        name: 'За депозит',
                        key: 1
                    },
                    {
                        name: 'Бездепозитный',
                        key: 2
                    }
                ],
                methods: [
                    {
                        name: 'Бонусный баланс',
                        key: 0
                    },
                    {
                        name: 'Реальный баланс',
                        key: 1
                    }
                ]
            },
            datepiker: {
                direction: 'ltr',
                format: 'yyyy.mm.dd HH:MM',
                separator: ' - ',
                applyLabel: 'Применить',
                cancelLabel: 'Отмена',
                weekLabel: 'W',
                customRangeLabel: 'Настраиваемый диапазон',
                daysOfWeek: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
                monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                firstDay: 0
            },
            pikerparams: {
                showcalendars: true,
                closeonesc: true,
                autoapply: false,
                timePicker: true,
                timePicker24Hour: true,
                opens: 'right'
            },
            pikerranges: {
                'Один день': [this.$moment().toDate(), this.$moment().set({date: this.$moment().get('date') + 1}).toDate()],
                'Одна неделя': [this.$moment().toDate(), this.$moment().set({date: this.$moment().get('date') + 7}).toDate()],
                'Один месяц': [this.$moment().toDate(), this.$moment().set({date: this.$moment().get('date') + 30}).toDate()],
                'Два месяца': [this.$moment().toDate(), this.$moment().set({date: this.$moment().get('date') + 60}).toDate()],
                'Три месяца': [this.$moment().toDate(), this.$moment().set({date: this.$moment().get('date') + 90}).toDate()],
                'Пол года': [this.$moment().toDate(), this.$moment().set({date: this.$moment().get('date') + 180}).toDate()],
                'Один год': [this.$moment().toDate(), this.$moment().set({date: this.$moment().get('date') + 365}).toDate()]
            }
        }
    },
    methods: {
        createItem () {
            if(!this.data.user) {
                this.errors.user = 'Это поле обязательно'
                return
            }
            if(!this.data.date) {
                this.errors.date = 'Это поле обязательно'
                return
            }
            if(!this.data.method) {
                this.errors.method = 'Это поле обязательно'
                return
            }
            if(!this.data.wager) {
                this.errors.wager = 'Это поле обязательно'
                return
            }
            if(!this.data.type) {
                this.errors.type = 'Это поле обязательно'
                return
            }
            if(!this.data.action) {
                this.errors.action = 'Это поле обязательно'
                return
            }

            if(this.data.type.key == 1) {

                if(!this.data.merchant.min_deposit && this.data.action.key != 2) {
                    this.errors.merchant.min_deposit = 'Это поле обязательно'     
                    this.refreshMerchantData()                   
                    return
                }

                if(!this.data.merchant.amount) {
                    this.errors.merchant.amount = 'Это поле обязательно'     
                    this.refreshMerchantData()                   
                    return
                }

            } else if(this.data.type.key == 2) {

                if(!this.data.game) {
                    this.errors.game = 'Это поле обязательно'
                    return
                }

                if(!this.data.freespins_number) {
                    this.errors.freespins_number = 'Это поле обязательно'
                    return
                }

                if(!this.data.merchant.min_deposit && this.data.action.key != 2) {
                    this.errors.merchant.min_deposit = 'Это поле обязательно'     
                    this.refreshMerchantData()                   
                    return
                }

                if(!this.data.merchant.bet) { 
                    this.errors.merchant.bet = 'Это поле обязательно'
                    this.refreshMerchantData()
                    return
                }

            } else if(this.data.type.key == 3) {

                if(!this.data.percent) {
                    this.errors.percent = 'Это поле обязательно'
                    return
                }

                if(!this.data.merchant.min_deposit) {
                    this.errors.merchant.min_deposit = 'Это поле обязательно'
                    this.refreshMerchantData()   
                    return
                }

                if(!this.data.merchant.max_deposit) {
                    this.errors.merchant.max_deposit = 'Это поле обязательно'
                    this.refreshMerchantData()  
                    return
                }

            }

            var data = {
                name: this.data.name,
                user: this.data.user.id,
                date: {
                    start: this.$moment(this.data.date.startDate).format('YYYY-MM-DD H:mm:ss'),
                    expire: this.$moment(this.data.date.endDate).format('YYYY-MM-DD H:mm:ss')
                },
                method: this.data.method.key,
                wager: this.data.wager,
                action: this.data.action.key,
                merchant: this.data.merchant
            }
            var type = null

            if(this.data.type.key == 1) {
                type = 'vaucher'
            } else if(this.data.type.key == 2) {
                type = 'freespin'
                data.game = this.data.game.id
                data.freespins_number = this.data.freespins_number
            } else if(this.data.type.key == 3) {
                type = 'percent'
                data.percent = this.data.percent
            }
            
            this.btn_preloader = true
            this.$axios.post(`/promotions/create/${type}`, JSON.stringify(data))
            .then((response) => {
                this.btn_preloader = false
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        this.$router.push({name: 'PromotionsItems'})
                        this.$toast.success(response.message)

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                this.btn_preloader = false
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errorByPatch(this.errors, response.field, response.message)
                        this.refreshMerchantData()
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })
        },
        searchGames (search, loading) {
            this.utils.games = []
            if(search.length >= 3) {
                loading(true)
                 this.$axios.post('/games/search/identificator', JSON.stringify({
                    search: search,
                    has_freespins: 1
                 }))
                .then((response) => {
                    response = response.data
                    switch (response.status) {
                        case "error":
                            this.$toast.error(response.message)
                            break
                        case "success":
                            loading(false)
                            for (let game of response.data) {
                                this.utils.games.push({
                                    id: game.id,
                                    uuid: game.uuid,
                                    name: game.name,
                                    image: game.image,
                                    is_mobile: game.is_mobile,
                                    is_desktop: game.is_desktop
                                })
                            }

                            this.setUser(response.user)
                            break
                        default:
                            this.$toast.error('Unknown error')
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if('status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            }
        },
        searchUsers (search, loading) {
            if(search.length >= 3) {
                loading(true)
                 this.$axios.post('/users/search/identificator', JSON.stringify({
                    search: search
                 }))
                .then((response) => {
                    response = response.data
                    switch (response.status) {
                        case "error":
                            this.$toast.error(response.message)
                            break
                        case "success":
                            loading(false)
                            this.utils.users = []
                            for (let user of response.data) {
                                this.utils.users.push({
                                    id: user.id,
                                    email: user.email
                                })
                            }

                            this.setUser(response.user)
                            break
                        default:
                            this.$toast.error('Unknown error')
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if('status' in response && response.status == 'error') {
                        if('field' in response) {
                            this.errors[response.field] = response.message
                        } else {
                            this.$toast.error(response.message)
                        }
                    }
                })
            }
        },
        getUserMerchant () {
            this.$axios.post('/merchants/search/merchant/user', JSON.stringify({
                user_id: this.data.user.id
             }))
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        this.utils.merchant = response.data
                        this.refreshMerchantData()

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })
        },
        openBetMerchant (merchant) {
            this.$axios.post(`/promotions/freespins/bets`, JSON.stringify({
                merchant: {
                    id: merchant.id,
                    currency: merchant.currency
                },
                game: {
                    id: this.data.game.id,
                    uuid: this.data.game.uuid
                }
            }))
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":

                        for(let item in response.data) {
                            response.data[item].total_bet_per_line = response.data[item].total_bet * response.data[item].denomination
                        }

                        this.utils.freespins_bets = response.data
                        this.refreshMerchantData()

                        this.setUser(response.user) 
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })
        },
        changeType () {
            this.data.merchant = {}
            this.utils.freespins_bets = []
            this.errors.merchant = {}

            if(this.data.type.key == 1) {
                this.data.merchant = {
                    min_deposit: null,
                    amount: null
                }

                this.errors.merchant = {
                    min_deposit: false,
                    amount: false
                }
            } else if(this.data.type.key == 2) {
                this.data.merchant = {
                    min_deposit: null,
                    bet: null
                }

                this.utils.freespins_bets = []

                this.errors.merchant = {
                    min_deposit: false,
                    bet: false
                }
            } else if(this.data.type.key == 3) {
                this.data.merchant = {
                    min_deposit: null,
                    max_deposit: null
                }

                this.errors.merchant = {
                    min_deposit: false,
                    max_deposit: false
                }
            }

            if(this.data.action.key == 2) {
                this.changeAction()
            }
        },
        changeAction() {

            if(this.data.action.key == 2) {
                this.data.merchant.min_deposit = String(0)
                this.errors.merchant.min_deposit = false

                this.utils.types = [
                    {
                        name: 'Ваучер',
                        key: 1
                    },
                    {
                        name: 'Бесплатные вращения',
                        key: 2
                    }
                ]

                if(this.data.type.key == 3) {
                   this.data.type = this.utils.types[0]
                }
            } else {
                this.data.merchant.min_deposit = null
                this.errors.merchant.min_deposit = false

                this.utils.types = [
                    {
                        name: 'Ваучер',
                        key: 1
                    },
                    {
                        name: 'Бесплатные вращения',
                        key: 2
                    },
                    {
                        name: 'Кэш Бонус',
                        key: 3
                    }
                ]
            }

        },
        errorByPatch (layer, path, value) {
            path = path.split('.')

            path.forEach((value, index) => {
                if (!isNaN(value)) {
                    path[index] = parseInt(value)
                }
            })

            for (var i = 0; i < path.length; i++) {
                if (value != null && i + 1 === path.length) {
                    layer[path[i]] = value
                }
                layer = layer[path[i]]
            }
            return layer
        },
        refreshMerchantData () {
            this.refresh_merchant_data = String(Date.now())
        }
    }
}
</script>

<style lang="css" scoped>
    ::v-deep .vs__clear {
        display: none;
    }
</style>
