<template>
    <main class="main">
        <div class="container-fluid px-0" data-layout="container">

            <aside-main/>

            <header-main/>

            <div class="content">

                <div class="card-header mb-5 d-flex flex-between-center">
                    <div class="d-flex align-items-center">
                        <h2 class="text-bold text-1100">
                            Баннеры
                            <span class="fw-normal text-700 ms-2">({{items_count}})</span>
                        </h2>
                    </div>

                    <div class="d-flex">
                        <router-link
                            :to="{name: 'BannersCreate'}"
                            class="btn btn-primary me-1 mb-1 float_right">
                            Создать
                        </router-link>
                    </div>
                </div>

                <div class="card-body p-0 spinner-center" v-if="preloader">
                    <div class="p-4 code-to-copy">
                        <div class="spinner-border" role="status"></div>
                    </div>
                </div>
                

                <div class="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-white border-y border-300 mt-2 position-relative top-1" v-show="!preloader">
                    <div class="table-responsive scrollbar ms-n1 ps-1" style="padding-bottom: 5px">
                        <div class="table table-responsive-small">
                            <datatable
                                :filter="search.search_change"
                                :columns="columns" 
                                :data="getItems"
                                :per-page="pager.per_page">
                                <template scope="{ row, columns }">
                                    <tr>
                                        <td>{{ row.id }}</td>
                                        <td>{{ row.name }}</td>
                                        <td>
                                            <img :src="`${$config.ApplicationDomainAPI}/banners/${row.image_small}`" alt="">
                                        </td>
                                        <td>{{ utils.languages.length ? utils.languages.find(el => el.key == row.language).name : null}}</td>
                                        <td>{{ $moment(row.created_at).format('YYYY-MM-DD H:mm:ss') }}</td>
                                        <td>{{ utils.statuses.find(el => el.key == row.status).name }}</td>
                                        <td>
                                            <button
                                                @click="changeStatus(row.id)"
                                                :content="row.status == 1 ? 'Отключить' : 'Включить'" 
                                                v-tippy="{arrow : true, arrowType: 'round'}"
                                                :class="{'disabled': status_item_preloader}"
                                                class="btn btn-soft-secondary btn-sm me-1 mb-1">
                                                <span class="spinner-border spinner-border-sm spinner-border-custom-small" role="status" v-if="status_item_preloader && status_item_preloader == row.id"></span>
                                                <i
                                                    v-if="!status_item_preloader || status_item_preloader != row.id"
                                                    :class="{'fa-circle-pause': row.status == 1, 'fa-play': row.status == 0}"
                                                    class="fa-regular fa-circle-pause"></i>
                                            </button>
                                            <router-link
                                                :to="{name: 'BannersItem', params: {id: row.id}}"
                                                content="Редактировать" 
                                                v-tippy="{arrow : true, arrowType: 'round'}"
                                                class="btn btn-soft-primary btn-sm me-1 mb-1">
                                                <i class="fa-regular fa-pen-to-square"></i>
                                            </router-link>
                                            <button
                                                @click="deleteItem(row.id)"
                                                content="Удалить" 
                                                v-tippy="{arrow : true, arrowType: 'round'}"
                                                class="btn btn-soft-danger btn-sm me-1 mb-1">
                                                <i class="fa-regular fa-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </template>

                                <template name="no-result">
                                    Ничего не найдено
                                </template>
                            </datatable>
                        </div>

                        <div class="col-auto d-flex">
                            <ul class="mb-3 pagination">
                                <datatable-pager v-model="pager.page" type="abbreviated"></datatable-pager>
                            </ul>
                        </div>
                    </div>
                </div>
                
                <footer-main/>

            </div>

        </div>
    </main>
</template>

<script>
export default {
    data () {
        return {
            preloader: true,
            status_item_preloader: false,
            pager: {
                per_page: 25,
                page: 0
            },
            items_count: 0,
            search: {
                search_change: null
            },
            columns: [
                {label: 'ID'},
                {label: 'Название'},
                {label: 'Превью'},
                {label: 'Язык'},
                {label: 'Дата'},
                {label: 'Статус'}
            ],
            utils: {
                languages: [],
                statuses: [
                    {
                        name: 'Включен',
                        key: 1
                    },
                    {
                        name: 'Отключен',
                        key: 0
                    }
                ]
            }
        }
    },
    methods: {
        async getItems ({ sortBy, sortDir, perPage, page }) {
            this.preloader = true

            var items = await this.$axios.post('/banners/items', JSON.stringify({
                pager: {
                    per_page: this.pager.per_page,
                    page: page - 1
                },
                sort: {
                    by: sortBy,
                    dir: sortDir
                }
            }))
            .then((response) => {
                this.preloader = false
                this.setUser(response.data.user)
                return response.data
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })

            if(items && items.status == 'success') {
                this.items_count = items.data.count
                return {
                    rows: items.data.items,
                    totalRowCount: items.data.count
                }
            }

            return {
                rows: [],
                totalRowCount: 0
            }
        },
        deleteItem (id) {
            this.$confirm('Вы уверены что хотите удалить баннер?').then(() => {
                this.$axios.post('/banners/delete', JSON.stringify({
                    id: id
                }))
                .then((response) => {
                    response = response.data
                    switch (response.status) {
                        case "error":
                            this.$toast.error(response.message)
                            break
                        case "success":
                            this.changeSearch()
                            this.$toast.success(response.message)

                            this.setUser(response.user)
                            break
                        default:
                            this.$toast.error('Unknown error')
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if('status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            })
        },
        changeStatus (id) {
            this.status_item_preloader = id
            this.$axios.post(`/banners/changestatus/${id}`)
            .then((response) => {
                this.status_item_preloader = false
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        this.changeSearch()

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)
                
                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        getLanguages () {
            this.$axios.post('utils/languages/items')
            .then((response) => {
                this.preloader = false
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        
                        for (let item in response.data) {
                            this.utils.languages.push({
                                name: response.data[item],
                                key: item
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    } else {
                        this.$toast.error(response.message)
                    }
                }

                if(error.response.status == 404) {
                    this.$router.push({name: 'BannersList'})
                }
            })
        },
        changeSearch () {
            this.search.search_change = String(Date.now())
        }
    },
    beforeMount() {
        this.getLanguages()
    }
}
</script>
